import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  Grid,
  InputAdornment,
  Paper,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const AdminWhatsapp = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchWhatsappNumber, setIsFetchWhatsappNumber] = useState(true);

  const fetchWhatsappNumber = async () => {
    setIsFetchWhatsappNumber(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/whatsapp`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        setValue(
          "whatsappNumber",
          response.data.admin_whatsapp_number.substr(
            response.data.admin_whatsapp_number.lastIndexOf("+") + 3
          )
        );
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchWhatsappNumber(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitSaveWhatsappNumber = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/whatsapp`,
        { whatsappNumber: "+62" + parseInt(data.whatsappNumber).toString() },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menyimpan perubahan",
          severity: "success",
        }));

        reset();
        fetchWhatsappNumber();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchWhatsappNumber();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchWhatsappNumber) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchWhatsappNumber]);

  return (
    <form onSubmit={handleSubmit(onSubmitSaveWhatsappNumber)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            {!isFetchWhatsappNumber ? (
              <TextField
                error={errors.whatsappNumber && true}
                fullWidth
                helperText={
                  errors.whatsappNumber && errors.whatsappNumber.message
                }
                label="nomor whatsapp"
                {...register("whatsappNumber", {
                  required: "Field tidak boleh kosong",
                })}
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    min: 0,
                    pattern: "[0-9]*",
                  },
                  startAdornment: (
                    <InputAdornment position="start">+62</InputAdornment>
                  ),
                }}
                type="number"
                variant="outlined"
              />
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        {/* snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar()}
          open={snackbar.isOpen}
        >
          <Alert
            onClose={() => handleCloseSnackbar()}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </form>
  );
};

export default AdminWhatsapp;
