import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import AdminProduct from "./pages/Admin/AdminProduct";
import AdminProductDetail from "./pages/Admin/AdminProductDetail";
import AdminProductUnit from "./pages/Admin/AdminProductUnit";
import AdminProductUnitDetail from "./pages/Admin/AdminProductUnitDetail";
import AdminProductColor from "./pages/Admin/AdminProductColor";
import AdminProductColorDetail from "./pages/Admin/AdminProductColorDetail";
import AdminProductSize from "./pages/Admin/AdminProductSize";
import AdminProductSizeDetail from "./pages/Admin/AdminProductSizeDetail";
import AdminProductCategory from "./pages/Admin/AdminProductCategory";
import AdminProductCategoryDetail from "./pages/Admin/AdminProductCategoryDetail";
import AdminFeatured from "./pages/Admin/AdminFeatured";
import AdminTrending from "./pages/Admin/AdminTrending";
import AdminAbout from "./pages/Admin/AdminAbout";
import AdminPromotion from "./pages/Admin/AdminPromotion";
import AdminPeople from "./pages/Admin/AdminPeople";
import AdminInvitation from "./pages/Admin/AdminInvitation";
import AdminChangePassword from "./pages/Admin/AdminChangePassword";
import AdminDoChangePassword from "./pages/Admin/AdminDoChangePassword";
import AdminWhatsapp from "./pages/Admin/AdminWhatsapp";
import AdminLocation from "./pages/Admin/AdminLocation";

import "./App.scss";

const IsLoggedInMiddlewareRoute = () => {
  const token = localStorage.getItem("access_token");

  return token !== null ? <Outlet /> : <Navigate to="login" />;
};

const theme = createTheme({
  palette: {
    primary: {
      main: deepOrange[700],
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/catalog"
            element={
              <>
                <Navbar />
                <Catalog />
                <Footer />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Navbar />
                <Login />
                <Footer />
              </>
            }
          />
          <Route element={<IsLoggedInMiddlewareRoute />}>
            <Route path="admin" element={<Admin />}>
              <Route path="product" element={<AdminProduct />} />
              <Route
                path="product/:product_id"
                element={<AdminProductDetail />}
              />
              <Route path="unit" element={<AdminProductUnit />} />
              <Route
                path="unit/:product_unit_id"
                element={<AdminProductUnitDetail />}
              />
              <Route path="color" element={<AdminProductColor />} />
              <Route
                path="color/:product_color_id"
                element={<AdminProductColorDetail />}
              />
              <Route path="size" element={<AdminProductSize />} />
              <Route
                path="size/:product_size_id"
                element={<AdminProductSizeDetail />}
              />
              <Route path="category" element={<AdminProductCategory />} />
              <Route
                path="category/:product_category_id"
                element={<AdminProductCategoryDetail />}
              />
              <Route path="featured" element={<AdminFeatured />} />
              <Route path="trending" element={<AdminTrending />} />
              <Route path="about" element={<AdminAbout />} />
              <Route path="promotion" element={<AdminPromotion />} />
              <Route path="people" element={<AdminPeople />} />
              <Route path="invitation" element={<AdminInvitation />} />
              <Route path="changepassword" element={<AdminChangePassword />} />
              <Route path="changewa" element={<AdminWhatsapp />} />
              <Route path="changelocation" element={<AdminLocation />} />
            </Route>
          </Route>
          <Route
            path="adminchangepassword/:token"
            element={<AdminDoChangePassword />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
