import React, { useEffect, useState } from "react";
import axios from "axios";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { Skeleton } from "@mui/material";

import "./Contact.scss";

const Contact = () => {
  const [isFetchWhatsappNumber, setIsFetchWhatsappNumber] = useState(true);
  const [isFetchMapLocation, setIsFetchMapLocation] = useState(true);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [mapLocation, setMapLocation] = useState("");

  const fetchWhatsappNumber = async () => {
    setIsFetchWhatsappNumber(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/whatsapp`
      );

      if (response.status === 200) {
        setWhatsappNumber(
          response.data.admin_whatsapp_number.substr(
            response.data.admin_whatsapp_number.lastIndexOf("+") + 1
          )
        );
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchWhatsappNumber(false);
  };

  const fetchMapLocation = async () => {
    setIsFetchMapLocation(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/location`
      );

      if (response.status === 200) {
        setMapLocation(response.data.admin_location_url);
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchMapLocation(false);
  };

  useEffect(() => {
    fetchWhatsappNumber();
    fetchMapLocation();
  }, []);

  return (
    <section className="contact">
      <img
        className="contact__image-banner"
        src="assets/images/contact/contact_banner.png"
        alt="duniadaleman contact"
      />
      <div className="contact__card-container">
        <div className="contact__card">
          <FontAwesomeIcon className="contact__icon" icon={faWhatsapp} />
          <h1>hubungi kami sekarang</h1>
          <p>
            Ingin ikut andil dalam pemerataan ekonomi melalui kemitraan kami?
            Hubungi kami untuk informasi lebih lanjut
          </p>
          <a
            className="contact__phone-number"
            href={
              !isFetchWhatsappNumber
                ? `https://wa.me/${whatsappNumber}`
                : "https://wa.me/"
            }
            target="__blank"
          >
            +{whatsappNumber}
          </a>
        </div>
        <div className="contact__card">
          <FontAwesomeIcon className="contact__icon" icon={faCommentDots} />
          <h1>tanya sesuatu</h1>
          <p>
            Jika Anda memiliki pertanyaan atau membutuhkan bantuan lainnya, Anda
            dapat menghubungi kami segera.
          </p>
          <button className="btn">
            <a
              href={
                !isFetchWhatsappNumber
                  ? `https://wa.me/${whatsappNumber}`
                  : "https://wa.me/"
              }
              target="__blank"
            >
              chat sekarang
            </a>
          </button>
        </div>
      </div>
      {!isFetchMapLocation ? (
        <div
          className="contact__map"
          dangerouslySetInnerHTML={{
            __html: `${mapLocation}`,
          }}
        />
      ) : (
        <Skeleton animation="wave" variant="rectangular" height={300} />
      )}
    </section>
  );
};

export default Contact;
