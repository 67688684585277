import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete, Save, Send } from "@mui/icons-material";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

import "react-quill/dist/quill.snow.css";
import "./AdminPeople.scss";

const Input = styled("input")({
  display: "none",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "align",
  "list",
  "bullet",
  "indent",
];

const AdminPeople = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [
    isLoadingButtonSubmitImageGalleryClient,
    setIsLoadingButtonSubmitImageGalleryClient,
  ] = useState(false);
  const [isFetchPeople, setIsFetchPeople] = useState(true);
  const [isFetchPeopleImageGalleryClient, setIsFetchPeopleImageGalleryClient] =
    useState(true);
  const [people, setPeople] = useState({
    image_url: "",
    description: "",
  });
  const [uploadedImagePeople, setUploadedImagePeople] = useState(null);
  const [peopleImageGalleryClient, setPeopleImageGalleryClient] = useState([]);
  const [uploadedPeopleImageClient, setUploadedPeopleImageClient] =
    useState(null);
  const [selectedPeopleImageClient, setSelectedPeopleImageClient] =
    useState(null);

  const fetchPeople = async () => {
    setPeople({
      image_url: "",
      description: "",
    });
    setIsFetchPeople(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/people`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        setPeople({
          image_url: response.data.admin_people_image_url,
          description: response.data.admin_people_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchPeople(false);
  };

  const fetchAdminPeopleClientImages = async () => {
    setPeopleImageGalleryClient([]);
    setIsFetchPeopleImageGalleryClient(true);

    try {
      setPeopleImageGalleryClient([]);
      setIsFetchPeopleImageGalleryClient(true);

      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/people/client`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setPeopleImageGalleryClient(response.data);
      setIsFetchPeopleImageGalleryClient(false);
    } catch (error) {
      console.error(error);
    }

    setIsFetchPeopleImageGalleryClient(false);
  };

  const handleCloseAlert = () => {
    setIsShowAlert(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickOpenDialog = (peopleImageGalleryClient) => {
    setIsOpenDialog(true);
    setSelectedPeopleImageClient(peopleImageGalleryClient);
  };

  const handleClickCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleChangePeopleDescription = (value) => {
    setPeople((people) => ({
      ...people,
      description: value,
    }));
  };

  const handleChoosePeopleImage = (value) => {
    setUploadedImagePeople(value);
  };

  const handleChoosePeopleImageClient = (value) => {
    setUploadedPeopleImageClient(value);
  };

  const handleClickUploadPeopleImageClient = async () => {
    setIsLoadingButtonSubmitImageGalleryClient(true);
    setUploadedPeopleImageClient(null);

    let formData = new FormData();

    Array.from(uploadedPeopleImageClient).forEach(
      (uploadedPeopleImageClient) => {
        // create unique id for image name
        const imageName = uuidv4();

        return formData.append(
          "images",
          uploadedPeopleImageClient,
          imageName + "." + uploadedPeopleImageClient.name.split(".").pop()
        );
      }
    );

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/people/client`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengunggah gambar",
          severity: "success",
        }));

        fetchAdminPeopleClientImages();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmitImageGalleryClient(false);
  };

  const handleClickDeletePeopleImageClient = async () => {
    setIsOpenDialog(false);

    try {
      const response = await axiosToken.delete(
        `${process.env.REACT_APP_API_URL}/admin/people/client`,
        {
          data: { imageName: selectedPeopleImageClient },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menghapus gambar",
          severity: "success",
        }));

        fetchAdminPeopleClientImages();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  const onSubmitEditPeople = async () => {
    setIsLoadingButtonSubmit(true);

    let formData = new FormData();

    if (uploadedImagePeople) {
      const imageName =
        uuidv4() + "." + uploadedImagePeople.name.split(".").pop();

      formData.append("peopleImage", uploadedImagePeople, imageName);
      formData.append("peopleImageUrl", imageName);
    }
    formData.append("peopleDescription", people.description);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/people`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menyimpan perubahan",
          severity: "success",
        }));

        fetchPeople();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchPeople();
    fetchAdminPeopleClientImages();
  }, []);

  useEffect(() => {
    if (!isFetchPeople && !isFetchPeopleImageGalleryClient) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchPeople, isFetchPeopleImageGalleryClient]);

  return (
    <Stack spacing={2}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Collapse in={isShowAlert}>
          <Alert onClose={() => handleCloseAlert()} severity="info">
            <AlertTitle>Info</AlertTitle>
            untuk ukuran gambar gunakan — <strong>800 x 400</strong>
          </Alert>
        </Collapse>

        <Stack
          alignItems="center"
          direction={{ xs: "column", sm: "row" }}
          py={2}
        >
          <Typography mr={{ xs: 0, sm: "auto" }} variant="h5">
            daftar gambar klien
          </Typography>
          <label htmlFor={`upload-people-image-client`}>
            <Input
              accept="image/*"
              id={`upload-people-image-client`}
              multiple
              onChange={(e) => {
                handleChoosePeopleImageClient(e.target.files);
              }}
              type="file"
            />
            <Button
              component="span"
              startIcon={<FontAwesomeIcon icon={faUpload} />}
              sx={{
                m: 1,
              }}
              variant="contained"
            >
              pilih gambar
            </Button>
          </label>
          <LoadingButton
            disabled={uploadedPeopleImageClient === null ? true : false}
            endIcon={<Send />}
            loading={isLoadingButtonSubmitImageGalleryClient}
            loadingPosition="end"
            onClick={() => handleClickUploadPeopleImageClient()}
            type="submit"
            variant="contained"
          >
            unggah gambar
          </LoadingButton>
        </Stack>

        <Grid container spacing={2}>
          {!isFetchPeopleImageGalleryClient ? (
            peopleImageGalleryClient.map((peopleImageGalleryClient, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <div className="admin__people-image-client">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/people/client/${peopleImageGalleryClient}`}
                      alt="duniadaleman admin client"
                    />
                    <div className="admin__people-image-client__delete-button">
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleClickOpenDialog(peopleImageGalleryClient)
                        }
                        size="large"
                      >
                        <Delete fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              );
            })
          ) : (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="rectangular" height={300} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="rectangular" height={300} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="rectangular" height={300} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Skeleton animation="wave" variant="rectangular" height={300} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Collapse in={isShowAlert}>
          <Alert onClose={() => handleCloseAlert()} severity="info">
            <AlertTitle>Info</AlertTitle>
            untuk ukuran gambar gunakan — <strong>800 x 400</strong>
          </Alert>
        </Collapse>

        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">gambar pemilik</Typography>
              {!isFetchPeople ? (
                <Stack
                  alignItems="flex-end"
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                >
                  {people.image_url && (
                    <img
                      alt="duniadaleman admin people"
                      className="admin__people-image-preview"
                      src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/people/${people.image_url}`}
                    />
                  )}
                  <label htmlFor={`upload-people-image`}>
                    <Input
                      accept="image/*"
                      disabled={false}
                      id={`upload-people-image`}
                      onChange={(e) => {
                        handleChoosePeopleImage(e.target.files[0]);
                      }}
                      type="file"
                    />
                    <Button
                      component="span"
                      disabled={false}
                      startIcon={<FontAwesomeIcon icon={faUpload} />}
                      sx={{
                        m: 1,
                      }}
                      variant="contained"
                    >
                      pilih gambar
                    </Button>
                    <span>
                      {uploadedImagePeople && uploadedImagePeople.name}
                    </span>
                  </label>
                </Stack>
              ) : (
                <Skeleton animation="wave" variant="text" />
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h5">kata - kata pemilik</Typography>
              <ReactQuill
                formats={quillFormats}
                modules={quillModules}
                theme="snow"
                value={people.description}
                onChange={handleChangePeopleDescription}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                onClick={onSubmitEditPeople}
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* dialog */}
      <Dialog
        open={isOpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textTransform: "capitalize" }}>
          apakah anda yakin ingin menghapus gambar ini?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClickDeletePeopleImageClient}>ya</Button>
          <Button onClick={handleClickCloseDialog}>tidak</Button>
        </DialogActions>
      </Dialog>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AdminPeople;
