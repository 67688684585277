export const navbarScript = () => {
  const header = document.querySelector(".header");
  const featured = document.querySelector(".featured");
  const options = {
    root: null,
    treshold: 0,
    rootMargin: "0px",
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        header.classList.add("header--scrolled");
      } else {
        header.classList.remove("header--scrolled");
      }
    });
  }, options);

  observer.observe(featured);
};
