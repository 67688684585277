import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  ListSubheader,
  LinearProgress,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";

import { adminListItems } from "../../utils/adminListItems";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const AdminLayoutContext = React.createContext();

const AdminLayout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [listItemsHeader, setListItemsHeader] = useState([]);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickListItemHeader = (index) => {
    const temp = listItemsHeader.map((listItemHeader, idx) => {
      if (index === idx) {
        listItemHeader.isOpen = !listItemHeader.isOpen;
      }
      return listItemHeader;
    });
    setListItemsHeader(temp);
  };

  const initDrawerTitle = () => {
    adminListItems.forEach((listItems) => {
      listItems.props.forEach((listItem) => {
        if (location.pathname === listItem.path) {
          setDrawerTitle(listItem.text);
        }
      });
    });
  };

  const initListItems = () => {
    adminListItems.forEach((listItems) => {
      setListItemsHeader((listItemHeader) => [
        ...listItemHeader,
        { ...listItems, isOpen: true },
      ]);
    });
  };

  // init user menu items
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickLogout = async () => {
    if (
      localStorage.getItem("refresh_token") !== null &&
      localStorage.getItem("access_token") !== null
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/logout`,
          {
            token: localStorage.getItem("refresh_token"),
          }
        );

        if (response.status === 200) {
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
          navigate("/login");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      navigate("/login");
    }
  };

  const userMenuItems = [
    { label: "logout", onClick: () => handleClickLogout() },
  ];

  useEffect(() => {
    initListItems();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    initDrawerTitle();

    // eslint-disable-next-line
  }, [location]);

  return (
    <AdminLayoutContext.Provider
      value={{ isLoading, setIsLoading, setDrawerTitle }}
    >
      <Box sx={{ display: "flex", textTransform: "capitalize" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ paddingRight: "0 !important" }}
          open={open}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {drawerTitle}
            </Typography>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Buka Pengaturan">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="duniadaleman admin"
                    src="/assets/images/owner.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenuItems.map((userMenuItem, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={userMenuItem.onClick}
                      sx={{ textTransform: "capitalize" }}
                      textAlign="center"
                    >
                      {userMenuItem.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
          {isLoading && <LinearProgress />}
        </AppBar>
        <Drawer
          sx={{
            flexShrink: 0,
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>

          <List
            sx={{
              height: "100vh",
              overflow: "auto",
              position: "relative",
              py: 0,
            }}
          >
            {adminListItems.map((listItems, index) => {
              return (
                <div key={index}>
                  {listItemsHeader[index] && (
                    <>
                      <ListSubheader sx={{ padding: 0 }}>
                        <ListItemButton
                          onClick={() => handleClickListItemHeader(index)}
                        >
                          <ListItemIcon>
                            {listItemsHeader[index].icon}
                          </ListItemIcon>
                          <ListItemText primary={listItemsHeader[index].text} />
                          {listItemsHeader[index].isOpen ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                      </ListSubheader>
                      <Divider />
                      <Collapse
                        in={listItemsHeader[index].isOpen}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {listItems.props.map((listItem, index) => {
                            return (
                              <ListItemButton
                                key={index}
                                onClick={() => navigate(listItem.path)}
                                selected={location.pathname === listItem.path}
                                sx={{
                                  pl: 4,
                                  "&&.Mui-selected": {
                                    bgcolor: "rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover": {
                                    bgcolor: "rgba(0, 0, 0, 0.12)",
                                  },
                                }}
                              >
                                <ListItemIcon>{listItem.icon}</ListItemIcon>
                                <ListItemText primary={listItem.text} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </>
                  )}
                </div>
              );
            })}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    </AdminLayoutContext.Provider>
  );
};

export default AdminLayout;
