import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { NavLink, useLocation } from "react-router-dom";

import "./Footer.scss";

const Footer = () => {
  const location = useLocation();
  const [isFetchWhatsappNumber, setIsFetchWhatsappNumber] = useState(true);
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const fetchWhatsappNumber = async () => {
    setIsFetchWhatsappNumber(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/whatsapp`
      );

      if (response.status === 200) {
        setWhatsappNumber(
          response.data.admin_whatsapp_number.substr(
            response.data.admin_whatsapp_number.lastIndexOf("+") + 1
          )
        );
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchWhatsappNumber(false);
  };

  useEffect(() => {
    fetchWhatsappNumber();
  }, []);

  return (
    <footer className="footer">
      {(location.pathname === "/" || location.pathname === "/login") && (
        <div className="footer__waves">
          <div
            className="footer__wave footer__wave--1"
            style={{ backgroundImage: "url('/assets/images/wave.png')" }}
          ></div>
          <div
            className="footer__wave footer__wave--2"
            style={{ backgroundImage: "url('/assets/images/wave.png')" }}
          ></div>
          <div
            className="footer__wave footer__wave--3"
            style={{ backgroundImage: "url('/assets/images/wave.png')" }}
          ></div>
          <div
            className="footer__wave footer__wave--4"
            style={{ backgroundImage: "url('/assets/images/wave.png')" }}
          ></div>
        </div>
      )}

      <ul className="footer__social-icon-container">
        <li>
          {/* eslint-disable-next-line */}
          <a href="" target="__blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li>
          {/* eslint-disable-next-line */}
          <a href="" target="__blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>
        <li>
          {/* eslint-disable-next-line */}
          <a
            href={
              !isFetchWhatsappNumber ? `https://wa.me/${whatsappNumber}` : ""
            }
            target="__blank"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </li>
      </ul>
      <ul className="footer__menu">
        <li>
          <NavLink to="/">beranda</NavLink>
        </li>
        <li>
          <NavLink to="/catalog">katalog</NavLink>
        </li>
        <li>
          <NavLink to="/contact">kontak</NavLink>
        </li>
      </ul>
      <div className="footer__copyright">
        <p>&copy; 2022 dunia daleman | all rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
