import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

import "react-quill/dist/quill.snow.css";
import "./AdminInvitation.scss";

const Input = styled("input")({
  display: "none",
});

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "align",
  "list",
  "bullet",
  "indent",
];

const AdminInvitation = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchInvitation, setIsFetchInvitation] = useState(true);
  const [invitation, setInvitation] = useState({
    video_url: "",
    description: "",
  });
  const [uploadedVideo, setUploadedVideo] = useState(null);

  const fetchInvitation = async () => {
    setInvitation({
      video_url: "",
      description: "",
    });
    setIsFetchInvitation(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/invitation`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        setInvitation({
          video_url: response.data.admin_invitation_video_url,
          description: response.data.admin_invitation_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchInvitation(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleChangeInvitationDescription = (value) => {
    setInvitation((invitation) => ({
      ...invitation,
      description: value,
    }));
  };

  const handleChooseInvitationVideo = (value) => {
    setUploadedVideo(value);
  };

  const onSubmitEditInvitation = async () => {
    setIsLoadingButtonSubmit(true);

    let formData = new FormData();

    if (uploadedVideo) {
      const videoName = uuidv4() + "." + uploadedVideo.name.split(".").pop();

      formData.append("invitationVideo", uploadedVideo, videoName);
      formData.append("invitationVideoUrl", videoName);
    }
    formData.append("invitationDescription", invitation.description);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/invitation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menyimpan perubahan, silakan memuat ulang halaman",
          severity: "success",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchInvitation();
  }, []);

  useEffect(() => {
    if (!isFetchInvitation) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchInvitation]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Grid container p={2} spacing={2}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">video undangan</Typography>
            {!isFetchInvitation ? (
              <Stack
                alignItems="flex-end"
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
              >
                {invitation.video_url && (
                  <video
                    autoPlay
                    className="admin__invitation__video-preview"
                    controls
                    loop
                    muted
                    width="750"
                  >
                    <source
                      src={`${process.env.REACT_APP_API_URL}/uploads/admin/videos/invitation/${invitation.video_url}`}
                      type="video/mp4"
                    />
                  </video>
                )}
                <label htmlFor={`upload-invitation-video`}>
                  <Input
                    accept="video/mp4,video/x-m4v,video/*"
                    disabled={false}
                    id={`upload-invitation-video`}
                    onChange={(e) => {
                      handleChooseInvitationVideo(e.target.files[0]);
                    }}
                    type="file"
                  />
                  <Button
                    component="span"
                    disabled={false}
                    startIcon={<FontAwesomeIcon icon={faUpload} />}
                    sx={{
                      m: 1,
                    }}
                    variant="contained"
                  >
                    pilih video
                  </Button>
                  <span>{uploadedVideo && uploadedVideo.name}</span>
                </label>
              </Stack>
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant="h5">deksripsi undangan</Typography>
            <ReactQuill
              formats={quillFormats}
              modules={quillModules}
              theme="snow"
              value={invitation.description}
              onChange={handleChangeInvitationDescription}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              endIcon={<Save />}
              loading={isLoadingButtonSubmit}
              loadingPosition="end"
              onClick={onSubmitEditInvitation}
              type="submit"
              variant="contained"
            >
              simpan perubahan
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdminInvitation;
