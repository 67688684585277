import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import AdminLayout from "../../components/AdminLayout";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.split("/").join("") === "admin") {
      navigate("product");
    }
  }, [location.pathname, navigate]);

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default Admin;
