import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import {
  Alert,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoginOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Login = () => {
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onSubmitLogin = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        { email: data.email, password: data.password }
      );

      if (response.status === 200) {
        localStorage.setItem("refresh_token", response.data.refreshToken);
        localStorage.setItem("access_token", response.data.accessToken);

        if (response.data.isAdmin) {
          navigate("/admin");
        }
      } else if (response.status === 204) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "email atau password salah",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/admin");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmitLogin)}
      style={{ textTransform: "capitalize" }}
    >
      <Stack
        direction="column"
        py={30}
        sx={{
          backgroundImage: "url('/assets/images/login/login_background.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid container p={2} spacing={5}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  sx={{ fontWeight: "800" }}
                  textAlign="center"
                  variant="h4"
                >
                  Login
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.email && true}
                  fullWidth
                  helperText={errors.email && errors.email.message}
                  label="Email"
                  {...register("email", {
                    required: "Field tidak boleh kosong",
                  })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    error={errors.password && true}
                    htmlFor="password"
                  >
                    Kata Sandi
                  </InputLabel>
                  <OutlinedInput
                    error={errors.password && true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {isShowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    id="password"
                    label="kata sandi"
                    type={isShowPassword ? "text" : "password"}
                    {...register("password", {
                      required: "Field tidak boleh kosong",
                    })}
                  />
                  {errors.password && (
                    <FormHelperText error>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <LoadingButton
                    endIcon={<LoginOutlined />}
                    loading={isLoadingButtonSubmit}
                    loadingPosition="end"
                    type="submit"
                    variant="contained"
                  >
                    masuk
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Stack>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default Login;
