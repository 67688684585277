import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete, Send } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

import "./AdminFeatured.scss";

const Input = styled("input")({
  display: "none",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminFeatured = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchFeaturedImageGallery, setIsFetchFeaturedImageGallery] =
    useState(true);
  const [featuredImageGallery, setFeaturedImageGallery] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [selectedFeaturedImage, setSelectedFeaturedImage] = useState(null);

  const fetchAdminFeaturedImages = async () => {
    setFeaturedImageGallery([]);
    setIsFetchFeaturedImageGallery(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/featured`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setFeaturedImageGallery(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsFetchFeaturedImageGallery(false);
  };

  const handleCloseAlert = () => {
    setIsShowAlert(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickOpenDialog = (featuredImageGallery) => {
    setIsOpenDialog(true);
    setSelectedFeaturedImage(featuredImageGallery);
  };

  const handleClickCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleChooseFeaturedImage = (value) => {
    setFeaturedImage(value);
  };

  const handleClickUploadFeaturedImage = async () => {
    setIsLoadingButtonSubmit(true);
    setFeaturedImage(null);

    let formData = new FormData();

    Array.from(featuredImage).forEach((featuredImage) => {
      // create unique id for image name
      const imageName = uuidv4();

      return formData.append(
        "images",
        featuredImage,
        imageName + "." + featuredImage.name.split(".").pop()
      );
    });

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/featured`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengunggah gambar",
          severity: "success",
        }));

        fetchAdminFeaturedImages();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  const handleClickDeleteImage = async () => {
    setIsOpenDialog(false);

    try {
      const response = await axiosToken.delete(
        `${process.env.REACT_APP_API_URL}/admin/featured`,
        {
          data: { imageName: selectedFeaturedImage },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menghapus gambar",
          severity: "success",
        }));

        fetchAdminFeaturedImages();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  useEffect(() => {
    fetchAdminFeaturedImages();
  }, []);

  useEffect(() => {
    if (!isFetchFeaturedImageGallery) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchFeaturedImageGallery]);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Collapse in={isShowAlert}>
        <Alert onClose={() => handleCloseAlert()} severity="info">
          <AlertTitle>Info</AlertTitle>
          untuk ukuran gambar gunakan — <strong>1920 x 1080</strong>
        </Alert>
      </Collapse>

      <Stack alignItems="center" direction={{ xs: "column", sm: "row" }} py={2}>
        <Typography mr={{ xs: 0, sm: "auto" }} variant="h5">
          daftar gambar unggulan
        </Typography>
        <label htmlFor={`upload-featured-image`}>
          <Input
            accept="image/*"
            id={`upload-featured-image`}
            multiple
            onChange={(e) => {
              handleChooseFeaturedImage(e.target.files);
            }}
            type="file"
          />
          <Button
            component="span"
            startIcon={<FontAwesomeIcon icon={faUpload} />}
            sx={{
              m: 1,
            }}
            variant="contained"
          >
            pilih gambar
          </Button>
        </label>
        <LoadingButton
          disabled={featuredImage === null ? true : false}
          endIcon={<Send />}
          loading={isLoadingButtonSubmit}
          loadingPosition="end"
          onClick={() => handleClickUploadFeaturedImage()}
          type="submit"
          variant="contained"
        >
          unggah gambar
        </LoadingButton>
      </Stack>

      <Grid container spacing={2}>
        {!isFetchFeaturedImageGallery ? (
          featuredImageGallery.map((featuredImageGallery, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <div className="admin__featured-image">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/featured/${featuredImageGallery}`}
                    alt="duniadaleman admin featured"
                  />
                  <div className="admin__featured-image__delete-button">
                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() =>
                        handleClickOpenDialog(featuredImageGallery)
                      }
                      size="large"
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            );
          })
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Skeleton animation="wave" variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Skeleton animation="wave" variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Skeleton animation="wave" variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Skeleton animation="wave" variant="rectangular" height={300} />
            </Grid>
          </>
        )}
      </Grid>

      {/* dialog */}
      <Dialog
        open={isOpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textTransform: "capitalize" }}>
          apakah anda yakin ingin menghapus gambar ini?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClickDeleteImage}>ya</Button>
          <Button onClick={handleClickCloseDialog}>tidak</Button>
        </DialogActions>
      </Dialog>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdminFeatured;
