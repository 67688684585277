import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const AdminProductColorDetail = () => {
  const params = useParams();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchProductColorDetail, setIsFetchProductColorDetail] =
    useState(true);

  const fetchProductColorDetail = async () => {
    setIsFetchProductColorDetail(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/color/${params.product_color_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setValue("productColorName", response.data.product_color_name);
      setValue("productColorHex", response.data.product_color_hex);
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductColorDetail(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitEditProductColor = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.put(
        `${process.env.REACT_APP_API_URL}/admin/product/color`,
        {
          productColorID: params.product_color_id,
          productColorName: data.productColorName,
          productColorHex: data.productColorHex,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengubah warna, silakan memuat ulang halaman",
          severity: "success",
        }));
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    adminLayoutContext.setDrawerTitle("Detail Warna");
    fetchProductColorDetail();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchProductColorDetail) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchProductColorDetail]);

  return (
    <form onSubmit={handleSubmit(onSubmitEditProductColor)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">ubah warna</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProductColorDetail ? (
              <Stack spacing={2}>
                <TextField
                  error={errors.productColorName && true}
                  fullWidth
                  helperText={
                    errors.productColorName && errors.productColorName.message
                  }
                  label="nama warna"
                  {...register("productColorName", {
                    required: "Field tidak boleh kosong",
                    maxLength: {
                      value: 30,
                      message: "Nama warna maksimal 30 karakter",
                    },
                  })}
                  variant="outlined"
                />
                <TextField
                  error={errors.productColorHex && true}
                  fullWidth
                  helperText={
                    errors.productColorHex && errors.productColorHex.message
                  }
                  label="kode warna"
                  {...register("productColorHex", {
                    required: "Field tidak boleh kosong",
                    maxLength: {
                      value: 30,
                      message: "Kode warna maksimal 30 karakter",
                    },
                  })}
                  variant="outlined"
                />
              </Stack>
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AdminProductColorDetail;
