import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBoxes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Autoplay,
  EffectCards,
  EffectCreative,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import simpleParallax from "simple-parallax-js";
import { Skeleton } from "@mui/material";

import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Home.scss";

const Home = () => {
  const [isFetchFeaturedImageGallery, setIsFetchFeaturedImageGallery] =
    useState(true);
  const [isFetchTrending, setIsFetchTrending] = useState(true);
  const [isFetchAbout, setIsFetchAbout] = useState(true);
  const [isFetchPromotion, setIsFetchPromotion] = useState(true);
  const [isFetchPeople, setIsFetchPeople] = useState(true);
  const [isFetchPeopleImageGalleryClient, setIsFetchPeopleImageGalleryClient] =
    useState(true);
  const [isFetchInvitation, setIsFetchInvitation] = useState(true);
  const [isFetchWhatsappNumber, setIsFetchWhatsappNumber] = useState(true);
  const [featuredImageGallery, setFeaturedImageGallery] = useState([]);
  const [trending, setTrending] = useState([]);
  const [about, setAbout] = useState({
    image_url: "",
    description: "",
  });
  const [promotion, setPromotion] = useState({
    image_url: "",
    description: "",
  });
  const [peopleImageGalleryClient, setPeopleImageGalleryClient] = useState([]);
  const [people, setPeople] = useState({
    image_url: "",
    description: "",
  });
  const [invitation, setInvitation] = useState({
    video_url: "",
    description: "",
  });
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const fetchFeaturedImages = async () => {
    setFeaturedImageGallery([]);
    setIsFetchFeaturedImageGallery(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/featured`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setFeaturedImageGallery(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsFetchFeaturedImageGallery(false);
  };

  const fetchTrending = async () => {
    try {
      setTrending([]);
      setIsFetchTrending(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/trending`
      );

      if (response.status === 200) {
        response.data.forEach((props) => {
          setTrending((trending) => [
            ...trending,
            {
              id: props.admin_trending_id,
              product_id: props.product_id,
              product_name: props.product_name,
              product_color_id: props.product_color_id,
              product_color_name: props.product_color_name,
              product_image_url: props.product_color_detail_image_url,
            },
          ]);
        });
      }

      setIsFetchTrending(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAbout = async () => {
    setAbout({
      image_url: "",
      description: "",
    });
    setIsFetchAbout(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/about`
      );

      if (response.status === 200) {
        setAbout({
          image_url: response.data.admin_about_image_url,
          description: response.data.admin_about_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchAbout(false);
  };

  const fetchPromotion = async () => {
    setPromotion({
      image_url: "",
      description: "",
    });
    setIsFetchPromotion(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/promotion`
      );

      if (response.status === 200) {
        setPromotion({
          image_url: response.data.admin_promotion_image_url,
          description: response.data.admin_promotion_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchPromotion(false);
  };

  const fetchPeople = async () => {
    setPeople({
      image_url: "",
      description: "",
    });
    setIsFetchPeople(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/people`
      );

      if (response.status === 200) {
        setPeople({
          image_url: response.data.admin_people_image_url,
          description: response.data.admin_people_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchPeople(false);
  };

  const fetchAdminPeopleClientImages = async () => {
    setPeopleImageGalleryClient([]);
    setIsFetchPeopleImageGalleryClient(true);

    try {
      setPeopleImageGalleryClient([]);
      setIsFetchPeopleImageGalleryClient(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/people/client`
      );

      setPeopleImageGalleryClient(response.data);
      setIsFetchPeopleImageGalleryClient(false);
    } catch (error) {
      console.error(error);
    }

    setIsFetchPeopleImageGalleryClient(false);
  };

  const fetchInvitation = async () => {
    setInvitation({
      video_url: "",
      description: "",
    });
    setIsFetchInvitation(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/invitation`
      );

      if (response.status === 200) {
        setInvitation({
          video_url: response.data.admin_invitation_video_url,
          description: response.data.admin_invitation_description,
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchInvitation(false);
  };

  const fetchWhatsappNumber = async () => {
    setIsFetchWhatsappNumber(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/whatsapp`
      );

      if (response.status === 200) {
        setWhatsappNumber(
          response.data.admin_whatsapp_number.substr(
            response.data.admin_whatsapp_number.lastIndexOf("+") + 1
          )
        );
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchWhatsappNumber(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    var promotion = document.getElementsByClassName("promotion__image");
    new simpleParallax(promotion, {
      delay: 0.5,
      orientation: "down",
      scale: 1.1,
      transition: "ease-out",
    });

    var whatsappIcon = document.getElementById("fixed-whatsapp-icon");

    whatsappIcon.addEventListener("mouseover", (e) => {
      whatsappIcon.classList.add("active");
    });
    whatsappIcon.addEventListener("mouseout", (e) => {
      whatsappIcon.classList.remove("active");
    });

    // fetch data
    fetchWhatsappNumber();
    fetchFeaturedImages();
    fetchTrending();
    fetchAbout();
    fetchPromotion();
    fetchPeople();
    fetchAdminPeopleClientImages();
    fetchInvitation();
  }, []);

  return (
    <div className="home">
      <div id="fixed-whatsapp-icon">
        <a
          href={
            !isFetchWhatsappNumber
              ? `https://wa.me/${whatsappNumber}`
              : "https://wa.me/"
          }
          target="__blank"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </div>
      <section className="featured">
        <Swiper
          className="home__swiper home__swiper--featured"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-120%", 0, -500],
            },
            next: {
              shadow: true,
              translate: ["120%", 0, -500],
            },
          }}
          effect={"creative"}
          grabCursor={true}
          loop={true}
          modules={[Autoplay, EffectCreative, Navigation, Pagination]}
          navigation={true}
          pagination={{ clickable: true }}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
        >
          {!isFetchFeaturedImageGallery ? (
            featuredImageGallery.map((featuredImageGallery, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/featured/${featuredImageGallery}`}
                    alt="duniadaleman featured"
                  />
                </SwiperSlide>
              );
            })
          ) : (
            <>
              <SwiperSlide>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={1080}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={1080}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={1080}
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </section>
      <section className="trending">
        <h1>produk terlaris</h1>
        <Swiper
          className="home__swiper home__swiper--trending"
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            // when window width is >= value
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            2100: {
              slidesPerView: 4,
              spaceBetween: 60,
            },
          }}
          grabCursor={true}
          modules={[Autoplay, Navigation, Pagination]}
          navigation={true}
          pagination={{ clickable: true }}
          slidesPerView={3}
          spaceBetween={30}
          observer={true}
          observeParents={true}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
        >
          {!isFetchTrending ? (
            trending.map((trending, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="home__swiper-slide__card">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/product/${trending.product_image_url}`}
                      alt="duniadaleman trending"
                    />
                    <p>
                      {trending.product_name} ({trending.product_color_name})
                    </p>
                  </div>
                </SwiperSlide>
              );
            })
          ) : (
            <>
              <SwiperSlide>
                <div className="home__swiper-slide__card">
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={500}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home__swiper-slide__card">
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={500}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="home__swiper-slide__card">
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={500}
                  />
                </div>
              </SwiperSlide>
            </>
          )}
        </Swiper>
        <button className="btn btn--black">
          <NavLink to="/catalog">lihat semua</NavLink>
        </button>
      </section>
      <section className="about">
        <div className="about__image-container">
          <img
            className="about__image-shadow"
            data-aos="fade-right"
            data-aos-once="true"
            src="assets/images/home/about_background_shadow.png"
            alt="duniadaleman about background shadow"
          />
          {!isFetchAbout ? (
            <img
              className="about__image-featured"
              data-aos="fade-right"
              data-aos-once="true"
              src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/about/${about.image_url}`}
              alt="duniadaleman about"
            />
          ) : (
            <Skeleton animation="wave" variant="rectangular" height={500} />
          )}
        </div>
        <div
          className="about__description"
          data-aos="fade-left"
          data-aos-once="true"
        >
          {!isFetchAbout ? (
            <>
              <h1>tentang dunia daleman</h1>
              <div
                className="about__description-content"
                dangerouslySetInnerHTML={{ __html: about.description }}
              />
            </>
          ) : (
            <Skeleton animation="wave" variant="text" sx={{ mb: 5 }} />
          )}
          <button
            className="btn btn--white"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <a
              href={
                !isFetchWhatsappNumber
                  ? `https://wa.me/${whatsappNumber}`
                  : "https://wa.me/"
              }
              target="__blank"
            >
              bergabung dengan kami
            </a>
          </button>
        </div>
      </section>
      <section className="promotion">
        <img
          className="promotion__image"
          src={
            !isFetchPromotion
              ? `${process.env.REACT_APP_API_URL}/uploads/admin/images/promotion/${promotion.image_url}`
              : ""
          }
          alt="duniadaleman promotion"
        />
        <div className="promotion__description">
          {!isFetchPromotion ? (
            <div
              data-aos="fade-up"
              data-aos-once="true"
              className="promotion__description-content"
              dangerouslySetInnerHTML={{ __html: promotion.description }}
            />
          ) : (
            <Skeleton animation="wave" variant="text" />
          )}
          <button
            className="btn btn--white"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <NavLink to="/catalog">beli sekarang</NavLink>
          </button>
        </div>
      </section>
      <section className="privilege">
        <h1 data-aos="fade-up" data-aos-once="true">
          kembangkan bisnis anda sebesar mungkin
        </h1>
        <div
          className="privilege__item"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <FontAwesomeIcon icon={faBoxes} className="privilege__icon" />
          <h2>bahan produksi sendiri</h2>
          <p>cintai produk indonesia</p>
        </div>
        <div
          className="privilege__item"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="privilege__certification-container">
            <img
              className="privilege__certification"
              src="assets/images/home/djki_logo.png"
              alt="duniadaleman djki certification"
            />
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="privilege__certification-icon"
            />
          </div>
          <h2>tersertifikasi oleh DJKI</h2>
          <p>terbukti aman dan terpercaya</p>
        </div>
        <div
          className="privilege__item"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <FontAwesomeIcon icon={faHandshake} className="privilege__icon" />
          <h2>dipercaya banyak pelanggan</h2>
          <p>kepercayaan anda adalah prioritas kami</p>
        </div>
      </section>
      <section className="people">
        <div className="people__client">
          <h1 data-aos="fade-up" data-aos-once="true">
            klien kami
          </h1>
          <Swiper
            className="people__swiper"
            data-aos="fade-up"
            data-aos-once="true"
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards, Pagination]}
            pagination={{ clickable: true }}
          >
            {!isFetchPeopleImageGalleryClient ? (
              peopleImageGalleryClient.map((client, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/people/client/${client}`}
                      alt="duniadaleman client"
                    />
                  </SwiperSlide>
                );
              })
            ) : (
              <>
                <SwiperSlide>
                  <div className="home__swiper-slide__card">
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={500}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="home__swiper-slide__card">
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={500}
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="home__swiper-slide__card">
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={500}
                    />
                  </div>
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </div>
        <div className="people__owner">
          <h1 data-aos="fade-up" data-aos-once="true">
            perkenalkan mr. heri
          </h1>
          <h2 data-aos="fade-up" data-aos-once="true">
            owner dunia daleman
          </h2>
          <div
            className="people__owner-detail"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <img
              src={
                !isFetchPeople
                  ? `${process.env.REACT_APP_API_URL}/uploads/admin/images/people/${people.image_url}`
                  : ""
              }
              alt="duniadaleman owner"
            />
            {!isFetchPeople ? (
              <div
                className="people__description-content"
                dangerouslySetInnerHTML={{ __html: people.description }}
              />
            ) : (
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={500}
                width="100%"
              />
            )}
          </div>
        </div>
      </section>
      <section className="invitation">
        <video
          className="invitation__video"
          src={
            !isFetchInvitation
              ? `${process.env.REACT_APP_API_URL}/uploads/admin/videos/invitation/${invitation.video_url}`
              : ""
          }
          autoPlay
          muted
          loop
        />
        <div
          className="invitation__description"
          data-aos="fade-up"
          data-aos-once="true"
        >
          {!isFetchInvitation ? (
            <div
              data-aos="fade-up"
              data-aos-once="true"
              className="invitation__description-content"
              dangerouslySetInnerHTML={{ __html: invitation.description }}
            />
          ) : (
            <Skeleton animation="wave" variant="text" sx={{ mb: 5 }} />
          )}
          <button
            className="btn btn--white"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <a
              href={
                !isFetchWhatsappNumber
                  ? `https://wa.me/${whatsappNumber}`
                  : "https://wa.me/"
              }
              target="__blank"
            >
              hubungi kami
            </a>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Home;
