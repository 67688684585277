import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileLines } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowTrendUp,
  faBox,
  faComments,
  faGears,
  faHouse,
  faLock,
  faMapLocationDot,
  faMarsAndVenus,
  faPalette,
  faRulerVertical,
  faTags,
  faUsers,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkroom } from "@mui/icons-material";

export const adminListItems = [
  {
    text: "produk",
    icon: <FontAwesomeIcon icon={faBox} />,
    props: [
      {
        text: "produk",
        icon: <Checkroom />,
        path: "/admin/product",
      },
      {
        text: "satuan",
        icon: <FontAwesomeIcon icon={faWeightHanging} />,
        path: "/admin/unit",
      },
      {
        text: "warna",
        icon: <FontAwesomeIcon icon={faPalette} />,
        path: "/admin/color",
      },
      {
        text: "ukuran",
        icon: <FontAwesomeIcon icon={faRulerVertical} />,
        path: "/admin/size",
      },
      {
        text: "kategori",
        icon: <FontAwesomeIcon icon={faMarsAndVenus} />,
        path: "/admin/category",
      },
    ],
  },
  {
    text: "pengaturan konten",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    props: [
      {
        text: "unggulan",
        icon: <FontAwesomeIcon icon={faHouse} />,
        path: "/admin/featured",
      },
      {
        text: "populer",
        icon: <FontAwesomeIcon icon={faArrowTrendUp} />,
        path: "/admin/trending",
      },
      {
        text: "tentang kami",
        icon: <FontAwesomeIcon icon={faUsers} />,
        path: "/admin/about",
      },
      {
        text: "promosi",
        icon: <FontAwesomeIcon icon={faTags} />,
        path: "/admin/promotion",
      },
      {
        text: "komunitas",
        icon: <FontAwesomeIcon icon={faComments} />,
        path: "/admin/people",
      },
      {
        text: "undangan",
        icon: <FontAwesomeIcon icon={faEnvelope} />,
        path: "/admin/invitation",
      },
    ],
  },
  {
    text: "lain - lain",
    icon: <FontAwesomeIcon icon={faGears} />,
    props: [
      {
        text: "ubah kata sandi",
        icon: <FontAwesomeIcon icon={faLock} />,
        path: "/admin/changepassword",
      },
      {
        text: "ubah nomor WA",
        icon: <FontAwesomeIcon icon={faWhatsapp} />,
        path: "/admin/changewa",
      },
      {
        text: "ubah lokasi",
        icon: <FontAwesomeIcon icon={faMapLocationDot} />,
        path: "/admin/changelocation",
      },
    ],
  },
];
