import axios from "axios";

const axiosToken = axios.create();

axiosToken.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 403) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/token`,
          { token: localStorage.getItem("refresh_token") }
        );

        if (response.status === 200) {
          localStorage.setItem("access_token", response.data);
          error.config.headers["Authorization"] = "Bearer " + response.data;

          return axios.request(error.config);
        }
      } catch (error) {
        console.error(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosToken;
