import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { Send, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const AdminDoChangePassword = () => {
  const params = useParams();
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Field tidak boleh kosong")
      .min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string()
      .required("Field tidak boleh kosong")
      .oneOf([Yup.ref("password")], "Password tidak sama"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isVerifyToken, setIsVerifyToken] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitChangePasword = async (data) => {
    setIsLoadingButtonSubmit(true);
    let valid = false;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/changepassword/verifytoken`,
        { token: params.token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        valid = true;
      }
    } catch (error) {
      console.error(error);
    }

    if (valid) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/dochangepassword`,
          { token: params.token, password: data.password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            isOpen: true,
            message: "berhasil mengubah kata sandi",
            severity: "success",
          }));

          reset();
        } else {
          setSnackbar((snackbar) => ({
            ...snackbar,
            isOpen: true,
            message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
            severity: "error",
          }));
        }
      } catch (error) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } else {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "sesi kadaluarsa, silakan kirim pesan ubah password lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  const verifyChangePasswordToken = async () => {
    setIsVerifyToken(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/changepassword/verifytoken`,
        { token: params.token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error(error);
    }

    setIsVerifyToken(false);
  };

  useEffect(() => {
    verifyChangePasswordToken();

    // eslint-disable-next-line
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmitChangePasword)}
      style={{ textTransform: "capitalize" }}
    >
      {!isVerifyToken ? (
        isAuthenticated ? (
          <Container maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 20, p: 2 }}>
              <Grid container p={2} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">ubah kata sandi</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      error={errors.password && true}
                      htmlFor="password"
                    >
                      Kata Sandi Baru
                    </InputLabel>
                    <OutlinedInput
                      error={errors.password && true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {isShowPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="password"
                      label="kata sandi baru"
                      type={isShowPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Field tidak boleh kosong",
                      })}
                    />
                    {errors.password && (
                      <FormHelperText error>
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      error={errors.confirmPassword && true}
                      htmlFor="confirm-password"
                    >
                      Ketik Ulang Kata Sandi
                    </InputLabel>
                    <OutlinedInput
                      error={errors.confirmPassword && true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {isShowConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      id="confirm-password"
                      label="ketik ulang kata sandi"
                      type={isShowConfirmPassword ? "text" : "password"}
                      {...register("confirmPassword", {
                        required: "Field tidak boleh kosong",
                      })}
                    />
                    {errors.confirmPassword && (
                      <FormHelperText error>
                        {errors.confirmPassword.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <LoadingButton
                      endIcon={<Send />}
                      loading={isLoadingButtonSubmit}
                      loadingPosition="end"
                      type="submit"
                      variant="contained"
                    >
                      ubah
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : (
          "error - 401"
        )
      ) : (
        "error - 404"
      )}

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AdminDoChangePassword;
