import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";

import { navbarScript } from "../../utils/navbarScript";
import { scrollToTop } from "../../utils/scrollToTop";

import "./Navbar.scss";

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navbarScript();
    } else {
      const header = document.querySelector(".header");
      header.classList.add("header--scrolled");
    }

    scrollToTop();
  }, [location]);

  const toggleHamburger = () => {
    document
      .querySelector(".header__hamburger")
      .classList.toggle("header__hamburger--active");
  };

  return (
    <header className="header">
      <div className="header__hamburger" onClick={toggleHamburger}>
        <div className="header__hamburger-icon"></div>
      </div>
      <NavLink
        className={({ isActive }) =>
          "header__link-logo" + (isActive ? " header__link-logo--active" : "")
        }
        to="/"
      >
        <img className="header__logo" src={logo} alt="duniadaleman logo" />
      </NavLink>
      <div className="header__nav-blur" onClick={toggleHamburger}></div>
      <nav className="header__nav">
        <ul className="header__ul">
          <li className="header__li">
            <NavLink
              className={({ isActive }) =>
                "header__link" + (isActive ? " header__link--active" : "")
              }
              onClick={toggleHamburger}
              to="/"
            >
              beranda
            </NavLink>
          </li>
          <li className="header__li">
            <NavLink
              className={({ isActive }) =>
                "header__link" + (isActive ? " header__link--active" : "")
              }
              onClick={toggleHamburger}
              to="/catalog"
            >
              katalog
            </NavLink>
          </li>
          <li className="header__li">
            <NavLink
              className={({ isActive }) =>
                "header__link" + (isActive ? " header__link--active" : "")
              }
              onClick={toggleHamburger}
              to="/contact"
            >
              kontak
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
