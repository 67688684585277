import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const AdminTrending = () => {
  const theme = useTheme();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isFetchProductColorDetail, setIsFetchProductColorDetail] =
    useState(true);
  const [isFetchTrending, setIsFetchTrending] = useState(true);
  const [productColorDetail, setProductColorDetail] = useState([]);
  const [trending, setTrending] = useState([]);

  const trendingColumns = useMemo(
    () => [
      { field: "id", width: 80, headerName: "ID", type: "number" },
      {
        field: "product_name",
        flex: 1,
        headerName: "nama produk",
        minWidth: 100,
        type: "string",
      },
      {
        field: "product_color_name",
        flex: 1,
        width: 50,
        headerName: "warna produk",
        minWidth: 100,
        type: "string",
      },
      {
        field: "product_image_url",
        flex: 1,
        headerName: "gambar produk",
        minWidth: 200,
        renderCell: (params) => (
          <img
            alt="duniadaleman product"
            src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/product/${params.value}`}
            style={{ height: "80%" }}
          />
        ),
      },
      {
        field: "actions",
        width: 80,
        headerName: "aksi",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip placement="bottom-start" title="Hilangkan">
                <VisibilityOff sx={{ color: theme.palette.error.main }} />
              </Tooltip>
            }
            label="detail"
            onClick={() => {
              handleClickRemoveFromTrending(params.row.id);
            }}
            sx={{ textTransform: "capitalize" }}
          />,
        ],
      },
    ],

    // eslint-disable-next-line
    []
  );

  const productColorDetailColumns = useMemo(
    () => [
      { field: "id", width: 80, headerName: "ID", type: "number" },
      {
        field: "product_name",
        flex: 1,
        headerName: "nama produk",
        minWidth: 100,
        type: "string",
      },
      {
        field: "product_color_name",
        flex: 1,
        headerName: "warna produk",
        minWidth: 100,
        type: "string",
      },
      {
        field: "product_image_url",
        flex: 1,
        headerName: "gambar produk",
        minWidth: 200,
        renderCell: (params) => (
          <img
            alt="duniadaleman admin product"
            src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/product/${params.value}`}
            style={{ height: "80%" }}
          />
        ),
      },
      {
        field: "actions",
        width: 80,
        headerName: "aksi",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip placement="bottom-start" title="Tampilkan">
                <Visibility sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            }
            label="detail"
            onClick={() => {
              handleClickAddToTrending(
                params.row.product_id,
                params.row.product_color_id
              );
            }}
            sx={{ textTransform: "capitalize" }}
          />,
        ],
      },
    ],

    // eslint-disable-next-line
    []
  );

  const fetchProductColorDetail = async () => {
    try {
      setProductColorDetail([]);
      setIsFetchProductColorDetail(true);

      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/trending/product`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        response.data.forEach((props) => {
          setProductColorDetail((productColorDetail) => [
            ...productColorDetail,
            {
              id: props.product_color_detail_id,
              product_id: props.product_id,
              product_name: props.product_name,
              product_color_id: props.product_color_id,
              product_color_name: props.product_color_name,
              product_image_url: props.product_color_detail_image_url,
            },
          ]);
        });
      }

      setIsFetchProductColorDetail(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTrending = async () => {
    try {
      setTrending([]);
      setIsFetchTrending(true);

      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/trending`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        response.data.forEach((props) => {
          setTrending((trending) => [
            ...trending,
            {
              id: props.admin_trending_id,
              product_id: props.product_id,
              product_name: props.product_name,
              product_color_id: props.product_color_id,
              product_color_name: props.product_color_name,
              product_image_url: props.product_color_detail_image_url,
            },
          ]);
        });
      }

      setIsFetchTrending(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickAddToTrending = async (productID, productColorID) => {
    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/trending`,
        { productID: productID, productColorID: productColorID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menampilkan produk",
          severity: "success",
        }));

        fetchTrending();
        fetchProductColorDetail();
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  const handleClickRemoveFromTrending = async (adminTrendingID) => {
    try {
      const response = await axiosToken.delete(
        `${process.env.REACT_APP_API_URL}/admin/trending`,
        {
          data: { adminTrendingID: adminTrendingID },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menghilangkan produk",
          severity: "success",
        }));

        fetchProductColorDetail();
        fetchTrending();
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  useEffect(() => {
    fetchProductColorDetail();
    fetchTrending();
  }, []);

  useEffect(() => {
    if (!isFetchProductColorDetail && !isFetchTrending) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchProductColorDetail, isFetchTrending]);

  return (
    <Stack spacing={2}>
      {/* list trending */}
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">daftar produk yang ditampilkan</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchTrending ? (
              <DataGrid
                autoHeight
                columns={trendingColumns}
                initialState={{
                  pagination: {
                    pageSize: 5,
                  },
                }}
                rowHeight={150}
                rows={trending}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
              />
            ) : (
              <Skeleton animation="wave" height={300} variant="rectangular" />
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* list product */}
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">daftar produk</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProductColorDetail ? (
              <DataGrid
                autoHeight
                columns={productColorDetailColumns}
                initialState={{
                  pagination: {
                    pageSize: 5,
                  },
                }}
                rowHeight={150}
                rows={productColorDetail}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
              />
            ) : (
              <Skeleton animation="wave" height={300} variant="rectangular" />
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AdminTrending;
