import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Paper,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Info, Send } from "@mui/icons-material";
import { useTheme, styled } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const Input = styled("input")({
  display: "none",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminProduct = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchProducts, setIsFetchProducts] = useState(true);
  const [isFetchProductUnit, setIsFetchProductUnit] = useState(true);
  const [isFetchProductColor, setIsFetchProductColor] = useState(true);
  const [isFetchProductSize, setIsFetchProductSize] = useState(true);
  const [isFetchProductCategory, setIsFetchProductCategory] = useState(true);
  const [products, setProducts] = useState([]);
  const [productUnit, setProductUnit] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const productColumns = useMemo(
    () => [
      { field: "id", width: 80, headerName: "ID", type: "number" },
      {
        field: "product_name",
        flex: 1,
        headerName: "nama produk",
        minWidth: 100,
        type: "string",
      },
      {
        field: "actions",
        width: 80,
        headerName: "aksi",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip placement="bottom-start" title="Lihat Detail">
                <Info sx={{ color: theme.palette.info.main }} />
              </Tooltip>
            }
            label="detail"
            onClick={() => {
              navigate(`${params.row.id}`);
            }}
            sx={{ textTransform: "capitalize" }}
          />,
          <GridActionsCellItem
            icon={<Delete sx={{ color: theme.palette.error.main }} />}
            label="hapus"
            onClick={() => {
              handleClickOpenDialog();
              setSelectedProduct(params.row);
            }}
            showInMenu
            sx={{ textTransform: "capitalize" }}
          />,
        ],
      },
    ],

    // eslint-disable-next-line
    []
  );

  const fetchProducts = async () => {
    setProducts([]);
    setIsFetchProducts(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        response.data.forEach((props) => {
          setProducts((product) => [
            ...product,
            {
              id: props.product_id,
              ...props,
            },
          ]);
        });
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchProducts(false);
  };

  const fetchProductUnit = async () => {
    setProductUnit([]);
    setIsFetchProductUnit(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/unit`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductUnit((productUnit) => [
          ...productUnit,
          {
            id: props.product_unit_id,
            isChecked: false,
            name: props.product_unit_name,
            price: 0,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductUnit(false);
  };

  const fetchProductColor = async () => {
    setProductColor([]);
    setIsFetchProductColor(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/color`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductColor((productColor) => [
          ...productColor,
          {
            hex: props.product_color_hex,
            id: props.product_color_id,
            image: null,
            isChecked: false,
            name: props.product_color_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductColor(false);
  };

  const fetchProductSize = async () => {
    setProductSize([]);
    setIsFetchProductSize(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/size`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductSize((productSize) => [
          ...productSize,
          {
            id: props.product_size_id,
            isChecked: false,
            name: props.product_size_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductSize(false);
  };

  const fetchProductCategory = async () => {
    setProductCategory([]);
    setIsFetchProductCategory(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductCategory((productCategory) => [
          ...productCategory,
          {
            id: props.product_category_id,
            isChecked: false,
            name: props.product_category_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductCategory(false);
  };

  const handleCloseAlert = () => {
    setIsShowAlert(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleClickCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleChangeProductUnitCheckbox = (index, value) => {
    const temp = productUnit.map((productUnit, idx) => {
      if (index === idx) {
        productUnit.isChecked = value;
      }
      return productUnit;
    });
    setProductUnit(temp);
  };

  const handleChangeProductColorCheckbox = (index, value) => {
    const temp = productColor.map((productColor, idx) => {
      if (index === idx) {
        productColor.isChecked = value;
      }
      return productColor;
    });
    setProductColor(temp);
  };

  const handleChangeProductSizeCheckbox = (index, value) => {
    const temp = productSize.map((productSize, idx) => {
      if (index === idx) {
        productSize.isChecked = value;
      }
      return productSize;
    });
    setProductSize(temp);
  };

  const handleChangeProductCategoryCheckbox = (index, value) => {
    const temp = productCategory.map((productCategory, idx) => {
      if (index === idx) {
        productCategory.isChecked = value;
      }
      return productCategory;
    });
    setProductCategory(temp);
  };

  const handleChangeProductUnitPrice = (index, value) => {
    const temp = productUnit.map((unit, idx) => {
      if (index === idx) {
        unit.price = value;
      }
      return unit;
    });
    setProductUnit(temp);
  };

  const handleChooseProductColorImage = (index, value) => {
    const temp = productColor.map((color, idx) => {
      if (index === idx) {
        color.image = value;
      }
      return color;
    });
    setProductColor(temp);
  };

  const handleClickDeleteProduct = async () => {
    setIsOpenDialog(false);
    try {
      const response = await axiosToken.delete(
        `${process.env.REACT_APP_API_URL}/admin/product`,
        {
          data: { productID: selectedProduct.id },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menghapus produk",
          severity: "success",
        }));

        fetchProducts();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  const onSubmitAddProduct = (data) => {
    // check unit
    let isUnitSelected = false;

    productUnit.forEach((productUnit) => {
      if (productUnit.isChecked === true) {
        isUnitSelected = true;
      }
    });

    if (!isUnitSelected) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "pilih minimal 1 jenis satuan produk",
        severity: "error",
      }));
    } else {
      // check color
      let colorValidStatusCode = 400;

      for (let i = 0; i < productColor.length; i++) {
        const color = productColor[i];

        if (color.isChecked === true && color.image === null) {
          colorValidStatusCode = 404;
          break;
        }

        if (color.isChecked === true) {
          colorValidStatusCode = 200;
        }
      }

      if (colorValidStatusCode === 400) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "pilih minimal 1 jenis warna produk",
          severity: "error",
        }));
      } else if (colorValidStatusCode === 404) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terdapat warna yang belum memiliki gambar",
          severity: "error",
        }));
      } else if (colorValidStatusCode === 200) {
        // check size
        let isSizeSelected = false;

        productSize.forEach((productSize) => {
          if (productSize.isChecked === true) {
            isSizeSelected = true;
          }
        });

        if (!isSizeSelected) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            isOpen: true,
            message: "pilih minimal 1 jenis ukuran produk",
            severity: "error",
          }));
        } else {
          // check category
          let isCategorySelected = false;

          productCategory.forEach((productCategory) => {
            if (productCategory.isChecked === true) {
              isCategorySelected = true;
            }
          });

          if (!isCategorySelected) {
            setSnackbar((snackbar) => ({
              ...snackbar,
              isOpen: true,
              message: "pilih minimal 1 jenis kategori produk",
              severity: "error",
            }));
          } else {
            insertProduct(data);
          }
        }
      }
    }
  };

  const insertProduct = async (data) => {
    setIsLoadingButtonSubmit(true);

    // destructure data
    const tempProductUnit = productUnit
      .map((productUnit) => {
        if (productUnit.isChecked === false) {
          return false;
        }
        return productUnit;
      })
      .filter((notUndefined) => notUndefined !== false);

    const tempProductColor = productColor
      .map((productColor) => {
        if (productColor.isChecked === false) {
          return false;
        }
        return productColor;
      })
      .filter((notUndefined) => notUndefined !== false);

    const tempProductSize = productSize
      .map((productSize) => {
        if (productSize.isChecked === false) {
          return false;
        }
        return productSize;
      })
      .filter((notUndefined) => notUndefined !== false);

    const tempProductCategory = productCategory
      .map((productCategory) => {
        if (productCategory.isChecked === false) {
          return false;
        }
        return productCategory;
      })
      .filter((notUndefined) => notUndefined !== false);

    data.productUnit = tempProductUnit;
    data.productColor = tempProductColor;
    data.productSize = tempProductSize;
    data.productCategory = tempProductCategory;

    let formData = new FormData();

    formData.append("data", JSON.stringify(data));
    data.productColor.forEach((productColor) => {
      const imageName = uuidv4();

      formData.append(
        "images",
        productColor.image,
        imageName + "." + productColor.image.name.split(".").pop()
      );
    });

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/product`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menambahkan produk",
          severity: "success",
        }));

        // reset input
        reset();

        const tempProductUnit = productUnit.map((productUnit) => {
          productUnit.isChecked = false;
          productUnit.price = 0;

          return productUnit;
        });
        setProductUnit(tempProductUnit);

        const tempProductColor = productColor.map((productColor) => {
          productColor.isChecked = false;
          productColor.image = null;

          return productColor;
        });
        setProductColor(tempProductColor);

        const tempProductSize = productSize.map((productSize) => {
          productSize.isChecked = false;

          return productSize;
        });
        setProductSize(tempProductSize);

        const tempProductCategory = productCategory.map((productCategory) => {
          productCategory.isChecked = false;

          return productCategory;
        });
        setProductCategory(tempProductCategory);

        // fetch products
        fetchProducts();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchProducts();
    fetchProductUnit();
    fetchProductColor();
    fetchProductSize();
    fetchProductCategory();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !isFetchProducts &&
      !isFetchProductUnit &&
      !isFetchProductColor &&
      !isFetchProductSize &&
      !isFetchProductCategory
    ) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [
    adminLayoutContext,
    isFetchProducts,
    isFetchProductUnit,
    isFetchProductColor,
    isFetchProductSize,
    isFetchProductCategory,
  ]);

  return (
    <Stack spacing={2}>
      {/* product list */}
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">daftar produk</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProducts ? (
              <DataGrid
                autoHeight
                columns={productColumns}
                initialState={{
                  pagination: {
                    pageSize: 5,
                  },
                }}
                rows={products}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
              />
            ) : (
              <Skeleton animation="wave" height={300} variant="rectangular" />
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* add product */}
      <form onSubmit={handleSubmit(onSubmitAddProduct)}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Collapse in={isShowAlert}>
            <Alert onClose={() => handleCloseAlert()} severity="info">
              <AlertTitle>Info</AlertTitle>
              untuk ukuran gambar gunakan — <strong>500 x 500</strong>
            </Alert>
          </Collapse>

          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">tambah produk</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.productName && true}
                fullWidth
                helperText={errors.productName && errors.productName.message}
                label="nama produk"
                {...register("productName", {
                  required: "Field tidak boleh kosong",
                  maxLength: {
                    value: 30,
                    message: "Nama produk maksimal 30 karakter",
                  },
                })}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h5">satuan produk</Typography>
                {!isFetchProductUnit ? (
                  productUnit.map((unit, index) => {
                    return (
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        key={index}
                        spacing={2}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={unit.isChecked}
                                onChange={(e) =>
                                  handleChangeProductUnitCheckbox(
                                    index,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={`${unit.name}`}
                          />
                        </FormGroup>
                        <TextField
                          disabled={!unit.isChecked}
                          fullWidth
                          InputProps={{
                            inputProps: {
                              inputMode: "numeric",
                              min: 0,
                              pattern: "[0-9]*",
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                          label={`Harga per ${unit.name}`}
                          onChange={(e) =>
                            handleChangeProductUnitPrice(index, e.target.value)
                          }
                          type="number"
                          variant="outlined"
                          value={unit.price}
                        />
                      </Stack>
                    );
                  })
                ) : (
                  <Skeleton animation="wave" variant="text" />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h5">gambar produk</Typography>
                {!isFetchProductColor ? (
                  productColor.map((color, index) => {
                    return (
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        key={index}
                        spacing={2}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={color.isChecked}
                                onChange={(e) =>
                                  handleChangeProductColorCheckbox(
                                    index,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={`${color.name}`}
                          />
                        </FormGroup>
                        <label htmlFor={`upload-product-image-${index}`}>
                          <Input
                            accept="image/*"
                            disabled={!color.isChecked}
                            id={`upload-product-image-${index}`}
                            onChange={(e) => {
                              handleChooseProductColorImage(
                                index,
                                e.target.files[0]
                              );
                            }}
                            type="file"
                          />
                          <Button
                            component="span"
                            disabled={!color.isChecked}
                            startIcon={<FontAwesomeIcon icon={faUpload} />}
                            sx={{
                              m: 1,
                            }}
                            variant="contained"
                          >
                            pilih gambar
                          </Button>
                          <span>{color.image && color.image.name}</span>
                        </label>
                      </Stack>
                    );
                  })
                ) : (
                  <Skeleton animation="wave" variant="text" />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h5">ukuran produk</Typography>
                {!isFetchProductSize ? (
                  <Stack direction="row" spacing={2}>
                    {productSize.map((size, index) => {
                      return (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={size.isChecked}
                                  onChange={(e) =>
                                    handleChangeProductSizeCheckbox(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={`${size.name}`}
                            />
                          </FormGroup>
                        </div>
                      );
                    })}
                  </Stack>
                ) : (
                  <Skeleton animation="wave" variant="text" />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h5">kategori produk</Typography>
                {!isFetchProductCategory ? (
                  <Stack direction="row" spacing={2}>
                    {productCategory.map((category, index) => {
                      return (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={category.isChecked}
                                  onChange={(e) =>
                                    handleChangeProductCategoryCheckbox(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={`${category.name}`}
                            />
                          </FormGroup>
                        </div>
                      );
                    })}
                  </Stack>
                ) : (
                  <Skeleton animation="wave" variant="text" />
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  endIcon={<Send />}
                  loading={isLoadingButtonSubmit}
                  loadingPosition="end"
                  type="submit"
                  variant="contained"
                >
                  tambahkan produk
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* dialog */}
        <Dialog
          open={isOpenDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClickCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ textTransform: "capitalize" }}>
            apakah anda yakin ingin menghapus produk ini?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClickCloseDialog}>tidak</Button>
            <Button onClick={handleClickDeleteProduct}>ya</Button>
          </DialogActions>
        </Dialog>

        {/* snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar()}
          open={snackbar.isOpen}
        >
          <Alert
            onClose={() => handleCloseSnackbar()}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </form>
    </Stack>
  );
};

export default AdminProduct;
