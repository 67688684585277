import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { scrollToTop } from "../../utils/scrollToTop";

import "./Catalog.scss";

let catalog = [];
let catalogDisplay = [];
let catalogFilter = {
  productSize: [],
  productCategory: [],
  productColor: [],
};

const Catalog = () => {
  const [isRerender, setIsRerender] = useState(false);
  const [isFetchCatalog, setIsFetchCatalog] = useState(true);
  const [isFetchProductColor, setIsFetchProductColor] = useState(true);
  const [isFetchProductSize, setIsFetchProductSize] = useState(true);
  const [isFetchProductCategory, setIsFetchProductCategory] = useState(true);
  const [isFetchWhatsappNumber, setIsFetchWhatsappNumber] = useState(true);
  const [productColor, setProductColor] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const fetchWhatsappNumber = async () => {
    setIsFetchWhatsappNumber(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/whatsapp`
      );

      if (response.status === 200) {
        setWhatsappNumber(
          response.data.admin_whatsapp_number.substr(
            response.data.admin_whatsapp_number.lastIndexOf("+") + 1
          )
        );
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchWhatsappNumber(false);
  };

  const fetchCatalog = async () => {
    catalog = [];
    catalogDisplay = [];
    catalogFilter = {
      productSize: [],
      productCategory: [],
      productColor: [],
    };
    setIsFetchCatalog(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/catalog`
      );

      if (response.status === 200) {
        response.data.product.forEach((product, index) => {
          catalog.push({
            id: product.product_id,
            name: product.product_name,
            productUnit: [],
            productColor: [],
            productSize: [],
            productCategory: [],
            productColorChecked: "",
            productImagePreview: "",
          });

          response.data.productUnit.forEach((productUnit) => {
            if (catalog[index].id === productUnit.product_id) {
              catalog[index].productUnit.push({
                unit_id: productUnit.product_unit_id,
                unit_name: productUnit.product_unit_name,
                unit_price: productUnit.product_unit_detail_price,
              });
            }
          });

          let CTR = 0;
          response.data.productColor.forEach((productColor) => {
            if (catalog[index].id === productColor.product_id) {
              if (CTR === 0) {
                catalog[index].productImagePreview =
                  productColor.product_color_detail_image_url;
                catalog[index].productColorChecked =
                  productColor.product_color_id;

                CTR += 1;
              }
              catalog[index].productColor.push({
                color_id: productColor.product_color_id,
                color_name: productColor.product_color_name,
                color_hex: productColor.product_color_hex,
                color_image_url: productColor.product_color_detail_image_url,
              });
            }
          });

          response.data.productSize.forEach((productSize) => {
            if (catalog[index].id === productSize.product_id) {
              catalog[index].productSize.push({
                size_id: productSize.product_size_id,
                size_name: productSize.product_size_name,
              });
            }
          });

          response.data.productCategory.forEach((productCategory) => {
            if (catalog[index].id === productCategory.product_id) {
              catalog[index].productCategory.push({
                category_id: productCategory.product_category_id,
                category_name: productCategory.product_category_name,
              });
            }
          });
        });

        catalogDisplay = [...catalog];
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchCatalog(false);
  };

  const fetchProductColor = async () => {
    setProductColor([]);
    setIsFetchProductColor(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/color`
      );

      response.data.forEach((props) => {
        setProductColor((productColor) => [
          ...productColor,
          {
            id: props.product_color_id,
            name: props.product_color_name,
            hex: props.product_color_hex,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductColor(false);
  };

  const fetchProductSize = async () => {
    setProductSize([]);
    setIsFetchProductSize(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/size`
      );

      response.data.forEach((props) => {
        setProductSize((productSize) => [
          ...productSize,
          {
            id: props.product_size_id,
            name: props.product_size_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductSize(false);
  };

  const fetchProductCategory = async () => {
    setProductCategory([]);
    setIsFetchProductCategory(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/category`
      );

      response.data.forEach((props) => {
        setProductCategory((productCategory) => [
          ...productCategory,
          {
            id: props.product_category_id,
            name: props.product_category_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductCategory(false);
  };

  const handleChangeColorRadio = (product_id, value) => {
    catalogDisplay.forEach((product) => {
      if (product.id === product_id) {
        product.productColorChecked = value;

        product.productColor.forEach((productColor) => {
          if (productColor.color_id === parseInt(value)) {
            product.productImagePreview = productColor.color_image_url;
          }
        });
      }
    });

    setIsRerender(!isRerender);
  };

  const handleChangeCatalogFilterProductCategoryCheckbox = (
    productCategory,
    checked
  ) => {
    if (checked) {
      catalogFilter.productCategory.push(productCategory.id);
    } else {
      catalogFilter.productCategory.forEach((category, index) => {
        if (category === productCategory.id) {
          catalogFilter.productCategory.splice(index, 1);
        }
      });
    }

    filterProduct();
  };

  const handleChangeCatalogFilterProductColorCheckbox = (
    productColor,
    checked
  ) => {
    if (checked) {
      catalogFilter.productColor.push(productColor.id);
    } else {
      catalogFilter.productColor.forEach((color, index) => {
        if (color === productColor.id) {
          catalogFilter.productColor.splice(index, 1);
        }
      });
    }

    filterProduct();
  };

  const handleChangeCatalogFilterProductSizeCheckbox = (
    productSize,
    checked
  ) => {
    if (checked) {
      catalogFilter.productSize.push(productSize.id);
    } else {
      catalogFilter.productSize.forEach((size, index) => {
        if (size === productSize.id) {
          catalogFilter.productSize.splice(index, 1);
        }
      });
    }

    filterProduct();
  };

  const filterProduct = () => {
    catalogDisplay = [...catalog];

    for (let i = catalogDisplay.length - 1; i >= 0; i--) {
      let foundCategory = false;
      let foundColor = false;
      let foundSize = false;

      // filter kategori
      if (catalogFilter.productCategory.length > 0) {
        const display = catalogDisplay[i];

        for (let j = 0; j < display.productCategory.length; j++) {
          const category = display.productCategory[j];

          if (catalogFilter.productCategory.includes(category.category_id)) {
            foundCategory = true;
          }
        }

        if (!foundCategory) {
          catalogDisplay.splice(i, 1);
        }
      } else {
        foundCategory = true;
      }

      // filter warna
      if (catalogFilter.productColor.length > 0 && foundCategory) {
        const display = catalogDisplay[i];

        for (let j = 0; j < display.productColor.length; j++) {
          const color = display.productColor[j];

          if (catalogFilter.productColor.includes(color.color_id)) {
            foundColor = true;
          }
        }

        if (!foundColor) {
          catalogDisplay.splice(i, 1);
        }
      } else {
        foundColor = true;
      }

      // filter ukuran
      if (catalogFilter.productSize.length > 0 && foundCategory && foundColor) {
        const display = catalogDisplay[i];

        for (let j = 0; j < display.productSize.length; j++) {
          const size = display.productSize[j];

          if (catalogFilter.productSize.includes(size.size_id)) {
            foundSize = true;
          }
        }

        if (!foundSize) {
          catalogDisplay.splice(i, 1);
        }
      } else {
        foundSize = true;
      }
    }

    setIsRerender(!isRerender);
  };

  useEffect(() => {
    fetchWhatsappNumber();
    fetchCatalog();
    fetchProductColor();
    fetchProductSize();
    fetchProductCategory();
  }, []);

  return (
    <section className="catalog">
      <Tooltip placement="top-end" title="Paling Atas">
        <div className="scroll-to-top" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
      </Tooltip>
      <img
        className="catalog__image-banner"
        src="assets/images/catalog/catalog_banner.png"
        alt="duniadaleman catalog"
      />
      <div className="catalog__content">
        <div className="catalog__filter-container">
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid className="catalog__filter-grid" container p={2} spacing={2}>
              <Grid className="catalog__filter-title" item xs={12}>
                <Typography variant="h5">filter</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                  <Divider>
                    <Chip label="kategori produk" />
                  </Divider>
                  {!isFetchProductCategory ? (
                    productCategory.map((productCategory, index) => {
                      return (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChangeCatalogFilterProductCategoryCheckbox(
                                      productCategory,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={`${productCategory.name}`}
                            />
                          </FormGroup>
                        </div>
                      );
                    })
                  ) : (
                    <Skeleton animation="wave" variant="text" />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                  <Divider>
                    <Chip label="warna produk" />
                  </Divider>
                  {!isFetchProductColor ? (
                    productColor.map((productColor, index) => {
                      return (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChangeCatalogFilterProductColorCheckbox(
                                      productColor,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={
                                `${productColor.name}`
                                // <div
                                //   style={{
                                //     alignItems: "center",
                                //     display: "flex",
                                //     gap: "0.5rem",
                                //   }}
                                // >
                                //   <div
                                //     className="catalog__product-color"
                                //     style={{
                                //       backgroundColor: productColor.hex,
                                //       borderRadius: "100%",
                                //     }}
                                //   />
                                //   {productColor.name}
                                // </div>
                              }
                            />
                          </FormGroup>
                        </div>
                      );
                    })
                  ) : (
                    <Skeleton animation="wave" variant="text" />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="column" spacing={2}>
                  <Divider>
                    <Chip label="ukuran produk" />
                  </Divider>
                  {!isFetchProductSize ? (
                    productSize.map((productSize, index) => {
                      return (
                        <div key={index}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChangeCatalogFilterProductSizeCheckbox(
                                      productSize,
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label={`${productSize.name}`}
                            />
                          </FormGroup>
                        </div>
                      );
                    })
                  ) : (
                    <Skeleton animation="wave" variant="text" />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div className="catalog__product-container">
          <Grid container spacing={6}>
            {!isFetchCatalog ? (
              catalogDisplay.map((product, index) => {
                return (
                  <Grid item key={index} xs={12} md={6} xl={3}>
                    <div className="catalog__product">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/product/${product.productImagePreview}`}
                        alt="duniadaleman catalog product"
                      />
                      <div className="catalog__product-description">
                        <div className="catalog__product-header">
                          <h3>{product.name}</h3>
                        </div>
                        <div className="catalog__product-body">
                          <h3>warna</h3>
                          <div className="catalog__product-color-container">
                            <RadioGroup
                              row
                              onChange={(e) =>
                                handleChangeColorRadio(
                                  product.id,
                                  e.target.value
                                )
                              }
                              value={product.productColorChecked}
                            >
                              {product.productColor.map(
                                (productColor, index2) => {
                                  return (
                                    <FormControlLabel
                                      control={<Radio />}
                                      key={index2}
                                      label={
                                        <div
                                          className="catalog__product-color"
                                          style={{
                                            backgroundColor:
                                              productColor.color_hex,
                                          }}
                                        />
                                      }
                                      value={productColor.color_id}
                                    />
                                  );
                                }
                              )}
                            </RadioGroup>
                          </div>
                          <h3>ukuran</h3>
                          <div className="catalog__product-size-container">
                            {product.productSize.map((productSize, index) => {
                              return (
                                <div
                                  className="catalog__product-size"
                                  key={index}
                                >
                                  {productSize.size_name}
                                </div>
                              );
                            })}
                          </div>
                          {/* <h3>perkiraan harga</h3>
                          <div className="catalog__product-price-container">
                            {product.productUnit.map((productUnit, index) => {
                              return (
                                <div
                                  className="catalog__product-unit"
                                  key={index}
                                >
                                  Rp. &nbsp;
                                  <strong>
                                    {new Intl.NumberFormat([
                                      "ban",
                                      "id",
                                    ]).format(productUnit.unit_price)}
                                  </strong>
                                  &nbsp; / &nbsp;
                                  {productUnit.unit_name}
                                </div>
                              );
                            })}
                          </div> */}
                        </div>
                        <div className="catalog__product-footer">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <a
                              href={
                                !isFetchWhatsappNumber
                                  ? `https://wa.me/${whatsappNumber}`
                                  : "https://wa.me/"
                              }
                              style={{ textDecoration: "none" }}
                              target="__blank"
                            >
                              <Button
                                component="span"
                                endIcon={<FontAwesomeIcon icon={faWhatsapp} />}
                                sx={{
                                  m: 1,
                                  mt: 3,
                                }}
                                variant="contained"
                              >
                                hubungi kami
                              </Button>
                            </a>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })
            ) : (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={300}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={300}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={300}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={300}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default Catalog;
