import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const AdminProductSizeDetail = () => {
  const params = useParams();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchProductSizeDetail, setIsFetchProductSizeDetail] =
    useState(true);

  const fetchProductSizeDetail = async () => {
    setIsFetchProductSizeDetail(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/size/${params.product_size_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setValue("productSizeName", response.data.product_size_name);
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductSizeDetail(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitEditProductSize = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.put(
        `${process.env.REACT_APP_API_URL}/admin/product/size`,
        {
          productSizeID: params.product_size_id,
          productSizeName: data.productSizeName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengubah ukuran, silakan memuat ulang halaman",
          severity: "success",
        }));
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    adminLayoutContext.setDrawerTitle("Detail ukuran");
    fetchProductSizeDetail();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchProductSizeDetail) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchProductSizeDetail]);

  return (
    <form onSubmit={handleSubmit(onSubmitEditProductSize)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">ubah ukuran</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProductSizeDetail ? (
              <TextField
                error={errors.productSizeName && true}
                fullWidth
                helperText={
                  errors.productSizeName && errors.productSizeName.message
                }
                label="nama ukuran"
                {...register("productSizeName", {
                  required: "Field tidak boleh kosong",
                  maxLength: {
                    value: 30,
                    message: "Nama ukuran maksimal 30 karakter",
                  },
                })}
                variant="outlined"
              />
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AdminProductSizeDetail;
