import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { Alert, Grid, Paper, Snackbar, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const AdminChangePassword = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitChangePassword = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/changepassword`,
        { emailTo: data.emailTo },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengirim pesan, silakan cek pesan anda",
          severity: "success",
        }));

        reset();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    adminLayoutContext.setIsLoading(false);
  }, [adminLayoutContext]);

  return (
    <form onSubmit={handleSubmit(onSubmitChangePassword)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={errors.emailTo && true}
              fullWidth
              helperText={errors.emailTo && errors.emailTo.message}
              label="email tujuan"
              {...register("emailTo", {
                required: "Field tidak boleh kosong",
                pattern: {
                  // eslint-disable-next-line
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "format email salah",
                },
              })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Send />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                kirim email
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AdminChangePassword;
