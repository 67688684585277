import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

import "./AdminProductDetail.scss";

const Input = styled("input")({
  display: "none",
});

const AdminProductDetail = () => {
  const params = useParams();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm();
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchProductDetail, setIsFetchProductDetail] = useState(true);
  const [isFetchProductUnit, setIsFetchProductUnit] = useState(true);
  const [isFetchProductColor, setIsFetchProductColor] = useState(true);
  const [isFetchProductSize, setIsFetchProductSize] = useState(true);
  const [isFetchProductCategory, setIsFetchProductCategory] = useState(true);
  const [productDetail, setProductDetail] = useState({});
  const [productUnitDetail, setProductUnitDetail] = useState([]);
  const [productColorDetail, setProductColorDetail] = useState([]);
  const [productSizeDetail, setProductSizeDetail] = useState([]);
  const [productCategoryDetail, setProductCategoryDetail] = useState([]);
  const [productUnit, setProductUnit] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  const fetchProductDetail = async () => {
    setProductDetail({});
    setProductUnitDetail([]);
    setProductColorDetail([]);
    setProductSizeDetail([]);
    setProductCategoryDetail([]);
    setIsFetchProductDetail(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/detail/${params.product_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setProductDetail(response.data.product);
      setProductUnitDetail(response.data.productUnit);
      setProductColorDetail(response.data.productColor);
      setProductSizeDetail(response.data.productSize);
      setProductCategoryDetail(response.data.productCategory);

      setValue("productName", response.data.product.product_name);
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductDetail(false);
  };

  const fetchProductUnit = async () => {
    setProductUnit([]);
    setIsFetchProductUnit(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/unit`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductUnit((productUnit) => [
          ...productUnit,
          {
            id: props.product_unit_id,
            isChecked: false,
            name: props.product_unit_name,
            price: 0,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductUnit(false);
  };

  const fetchProductColor = async () => {
    setProductColor([]);
    setIsFetchProductColor(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/color`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductColor((productColor) => [
          ...productColor,
          {
            hex: props.product_color_hex,
            id: props.product_color_id,
            image: null,
            isChecked: false,
            name: props.product_color_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductColor(false);
  };

  const fetchProductSize = async () => {
    setProductSize([]);
    setIsFetchProductSize(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/size`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductSize((productSize) => [
          ...productSize,
          {
            id: props.product_size_id,
            isChecked: false,
            name: props.product_size_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductSize(false);
  };

  const fetchProductCategory = async () => {
    setProductCategory([]);
    setIsFetchProductCategory(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductCategory((productCategory) => [
          ...productCategory,
          {
            id: props.product_category_id,
            isChecked: false,
            name: props.product_category_name,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductCategory(false);
  };

  const handleCloseAlert = () => {
    setIsShowAlert(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleChangeProductUnitCheckbox = (index, value) => {
    const temp = productUnit.map((unit, idx) => {
      if (index === idx) {
        unit.isChecked = value;
      }
      return unit;
    });
    setProductUnit(temp);
  };

  const handleChangeProductColorCheckbox = (index, value) => {
    const temp = productColor.map((color, idx) => {
      if (index === idx) {
        color.isChecked = value;
      }
      return color;
    });
    setProductColor(temp);
  };

  const handleChangeProductSizeCheckbox = (index, value) => {
    const temp = productSize.map((size, idx) => {
      if (index === idx) {
        size.isChecked = value;
      }
      return size;
    });
    setProductSize(temp);
  };

  const handleChangeProductCategoryCheckbox = (index, value) => {
    const temp = productCategory.map((category, idx) => {
      if (index === idx) {
        category.isChecked = value;
      }
      return category;
    });
    setProductCategory(temp);
  };

  const handleChangeProductUnitPrice = (index, value) => {
    const temp = productUnit.map((unit, idx) => {
      if (index === idx) {
        unit.price = value;
      }
      return unit;
    });
    setProductUnit(temp);
  };

  const handleChooseProductColorImage = (index, value) => {
    const temp = productColor.map((color, idx) => {
      if (index === idx) {
        color.image = value;
      }
      return color;
    });
    setProductColor(temp);
  };

  const initProductUnit = () => {
    let temp = productUnit;

    productUnit.forEach((productUnit, index) => {
      productUnitDetail.forEach((productUnitDetail) => {
        if (productUnit.id === productUnitDetail.product_unit_id) {
          temp[index].isChecked = true;
          temp[index].price = productUnitDetail.product_unit_detail_price;
        }
      });
    });

    setProductUnit(temp);
  };

  const initProductColor = () => {
    let temp = productColor;

    productColor.forEach((productColor, index) => {
      productColorDetail.forEach((productColorDetail) => {
        if (productColor.id === productColorDetail.product_color_id) {
          temp[index].isChecked = true;
          temp[index].imageUrl =
            productColorDetail.product_color_detail_image_url;
        }
      });
    });

    setProductColor(temp);
  };

  const initProductSize = () => {
    let temp = productSize;

    productSize.forEach((productSize, index) => {
      productSizeDetail.forEach((productSizeDetail) => {
        if (productSize.id === productSizeDetail.product_size_id) {
          temp[index].isChecked = true;
        }
      });
    });

    setProductSize(temp);
  };

  const initProductCategory = () => {
    let temp = productCategory;

    productCategory.forEach((productCategory, index) => {
      productCategoryDetail.forEach((productCategoryDetail) => {
        if (productCategory.id === productCategoryDetail.product_category_id) {
          temp[index].isChecked = true;
        }
      });
    });

    setProductCategory(temp);
  };

  const onSubmitEditProduct = (data) => {
    // check unit
    let isUnitSelected = false;

    productUnit.forEach((productUnit) => {
      if (productUnit.isChecked === true) {
        isUnitSelected = true;
      }
    });

    if (!isUnitSelected) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "pilih minimal 1 jenis satuan produk",
        severity: "error",
      }));
    } else {
      // check color
      let colorValidStatusCode = 400;

      for (let i = 0; i < productColor.length; i++) {
        let color = productColor[i];

        if (color.isChecked) {
          colorValidStatusCode = 200;

          if (color.image === null) {
            let valid = false;

            for (let j = 0; j < productColorDetail.length; j++) {
              let colorDetail = productColorDetail[j];

              if (
                color.id === colorDetail.product_color_id &&
                colorDetail.product_color_detail_image_url !== null
              ) {
                valid = true;
              }
            }

            if (valid === false) {
              colorValidStatusCode = 404;
              break;
            }
          }
        }
      }

      if (colorValidStatusCode === 400) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "pilih minimal 1 jenis warna produk",
          severity: "error",
        }));
      } else if (colorValidStatusCode === 404) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terdapat warna yang belum memiliki gambar",
          severity: "error",
        }));
      } else if (colorValidStatusCode === 200) {
        // check size
        let isSizeSelected = false;

        productSize.forEach((productSize) => {
          if (productSize.isChecked === true) {
            isSizeSelected = true;
          }
        });

        if (!isSizeSelected) {
          setSnackbar((snackbar) => ({
            ...snackbar,
            isOpen: true,
            message: "pilih minimal 1 jenis ukuran produk",
            severity: "error",
          }));
        } else {
          // check category
          let isCategorySelected = false;

          productCategory.forEach((productCategory) => {
            if (productCategory.isChecked === true) {
              isCategorySelected = true;
            }
          });

          if (!isCategorySelected) {
            setSnackbar((snackbar) => ({
              ...snackbar,
              isOpen: true,
              message: "pilih minimal 1 jenis kategori produk",
              severity: "error",
            }));
          } else {
            editProduct(data);
          }
        }
      }
    }
  };

  const editProduct = async (data) => {
    setIsLoadingButtonSubmit(true);

    // destructure data
    data.productID = productDetail.product_id;

    const tempProductUnit = productUnit
      .map((productUnit) => {
        if (productUnit.isChecked === false) {
          return false;
        }
        return productUnit;
      })
      .filter((notUndefined) => notUndefined !== false);

    const tempProductSize = productSize
      .map((productSize) => {
        if (productSize.isChecked === false) {
          return false;
        }
        return productSize;
      })
      .filter((notUndefined) => notUndefined !== false);

    const tempProductCategory = productCategory
      .map((productCategory) => {
        if (productCategory.isChecked === false) {
          return false;
        }
        return productCategory;
      })
      .filter((notUndefined) => notUndefined !== false);

    data.productUnit = tempProductUnit;
    data.productColor = productColor;
    data.productSize = tempProductSize;
    data.productCategory = tempProductCategory;

    let formData = new FormData();

    formData.append("data", JSON.stringify(data));
    data.productColor.forEach((productColor) => {
      const imageName = uuidv4();

      if (productColor.image !== null) {
        formData.append(
          "images",
          productColor.image,
          imageName + "." + productColor.image.name.split(".").pop()
        );
      }
    });

    try {
      const response = await axiosToken.put(
        `${process.env.REACT_APP_API_URL}/admin/product`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil mengubah produk, silakan memuat ulang halaman",
          severity: "success",
        }));
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    adminLayoutContext.setDrawerTitle("Detail Produk");
    fetchProductDetail();
    fetchProductUnit();
    fetchProductColor();
    fetchProductSize();
    fetchProductCategory();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !isFetchProductDetail &&
      !isFetchProductUnit &&
      !isFetchProductColor &&
      !isFetchProductSize &&
      !isFetchProductCategory
    ) {
      adminLayoutContext.setIsLoading(false);
      initProductUnit();
      initProductColor();
      initProductSize();
      initProductCategory();
    }

    // eslint-disable-next-line
  }, [
    adminLayoutContext,
    isFetchProductDetail,
    isFetchProductUnit,
    isFetchProductColor,
    isFetchProductSize,
    isFetchProductCategory,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmitEditProduct)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Collapse in={isShowAlert}>
          <Alert onClose={() => handleCloseAlert()} severity="info">
            <AlertTitle>Info</AlertTitle>
            untuk ukuran gambar gunakan — <strong>500 x 500</strong>
          </Alert>
        </Collapse>

        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">ubah produk</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProductDetail ? (
              <TextField
                error={errors.productName && true}
                fullWidth
                helperText={errors.productName && errors.productName.message}
                label="nama produk"
                {...register("productName", {
                  required: "Field tidak boleh kosong",
                  maxLength: {
                    value: 30,
                    message: "Nama produk maksimal 30 karakter",
                  },
                })}
                variant="outlined"
              />
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">satuan produk</Typography>
              {!isFetchProductUnit ? (
                productUnit.map((unit, index) => {
                  return (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      key={index}
                      spacing={2}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={unit.isChecked}
                              onChange={(e) =>
                                handleChangeProductUnitCheckbox(
                                  index,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={`${unit.name}`}
                        />
                      </FormGroup>
                      <TextField
                        disabled={!unit.isChecked}
                        fullWidth
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            min: 0,
                            pattern: "[0-9]*",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                        label={`Harga per ${unit.name}`}
                        onChange={(e) =>
                          handleChangeProductUnitPrice(index, e.target.value)
                        }
                        type="number"
                        variant="outlined"
                        value={unit.price}
                      />
                    </Stack>
                  );
                })
              ) : (
                <Skeleton animation="wave" variant="text" />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">gambar produk</Typography>
              {!isFetchProductColor ? (
                productColor.map((color, index) => {
                  return (
                    <Stack
                      alignItems="center"
                      direction={{ xs: "column", sm: "row" }}
                      key={index}
                      spacing={2}
                    >
                      {color.imageUrl !== undefined ? (
                        <img
                          alt="duniadaleman admin product detail"
                          className="admin__product-detail__image-preview"
                          src={`${process.env.REACT_APP_API_URL}/uploads/admin/images/product/${color.imageUrl}`}
                        />
                      ) : (
                        <Typography variant="body2">
                          <em>belum ada gambar</em>
                        </Typography>
                      )}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={color.isChecked}
                              onChange={(e) =>
                                handleChangeProductColorCheckbox(
                                  index,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={`${color.name}`}
                        />
                      </FormGroup>
                      <label htmlFor={`upload-product-image-${index}`}>
                        <Input
                          accept="image/*"
                          disabled={!color.isChecked}
                          id={`upload-product-image-${index}`}
                          onChange={(e) => {
                            handleChooseProductColorImage(
                              index,
                              e.target.files[0]
                            );
                          }}
                          type="file"
                        />
                        <Button
                          component="span"
                          disabled={!color.isChecked}
                          startIcon={<FontAwesomeIcon icon={faUpload} />}
                          sx={{
                            m: 1,
                          }}
                          variant="contained"
                        >
                          pilih gambar
                        </Button>
                        <span>{color.image && color.image.name}</span>
                      </label>
                    </Stack>
                  );
                })
              ) : (
                <Skeleton animation="wave" variant="text" />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">ukuran produk</Typography>
              {!isFetchProductSize ? (
                <Stack direction="row" spacing={2}>
                  {productSize.map((size, index) => {
                    return (
                      <div key={index}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={size.isChecked}
                                onChange={(e) =>
                                  handleChangeProductSizeCheckbox(
                                    index,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={`${size.name}`}
                          />
                        </FormGroup>
                      </div>
                    );
                  })}
                </Stack>
              ) : (
                <Skeleton animation="wave" variant="text" />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">kategori produk</Typography>
              {!isFetchProductCategory ? (
                <Stack direction="row" spacing={2}>
                  {productCategory.map((category, index) => {
                    return (
                      <div key={index}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={category.isChecked}
                                onChange={(e) =>
                                  handleChangeProductCategoryCheckbox(
                                    index,
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label={`${category.name}`}
                          />
                        </FormGroup>
                      </div>
                    );
                  })}
                </Stack>
              ) : (
                <Skeleton animation="wave" variant="text" />
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        onClose={() => handleCloseSnackbar()}
        open={snackbar.isOpen}
      >
        <Alert
          onClose={() => handleCloseSnackbar()}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default AdminProductDetail;
