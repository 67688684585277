import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

import "./AdminLocation.scss";

const AdminLocation = () => {
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchLocation, setIsFetchLocation] = useState(true);
  const [location, setLocation] = useState("");

  const fetchLocation = async () => {
    setIsFetchLocation(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/location`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200) {
        setLocation(response.data.admin_location_url);
        setValue("location", response.data.admin_location_url);
      }
    } catch (error) {
      console.error(error);
    }

    setIsFetchLocation(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const onSubmitSaveLocation = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/location`,
        { location: data.location },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menyimpan perubahan",
          severity: "success",
        }));

        reset();
        fetchLocation();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchLocation();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetchLocation) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchLocation]);

  return (
    <form onSubmit={handleSubmit(onSubmitSaveLocation)}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            {!isFetchLocation ? (
              <TextField
                error={errors.location && true}
                fullWidth
                helperText={errors.location && errors.location.message}
                label="link lokasi"
                {...register("location", {
                  required: "Field tidak boleh kosong",
                })}
                minRows={5}
                multiline
                variant="outlined"
              />
            ) : (
              <Skeleton animation="wave" variant="text" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                endIcon={<Save />}
                loading={isLoadingButtonSubmit}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                simpan perubahan
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">pratinjau peta</Typography>
          </Grid>

          <Grid item xs={12}>
            {!isFetchLocation ? (
              <div
                className="admin__map-preview"
                dangerouslySetInnerHTML={{ __html: location }}
              />
            ) : (
              <Skeleton animation="wave" variant="rectangular" height={300} />
            )}
          </Grid>
        </Grid>

        {/* snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar()}
          open={snackbar.isOpen}
        >
          <Alert
            onClose={() => handleCloseSnackbar()}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </form>
  );
};

export default AdminLocation;
