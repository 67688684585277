import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Info, Send } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import axiosToken from "../../../utils/axiosToken";
import { AdminLayoutContext } from "../../../components/AdminLayout";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminProductCategory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const adminLayoutContext = useContext(AdminLayoutContext);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isFetchProductCategory, setIsFetchProductCategory] = useState(true);
  const [productCategory, setProductCategory] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);

  const columns = useMemo(
    () => [
      { field: "id", width: 80, headerName: "ID", type: "number" },
      {
        field: "product_category_name",
        flex: 1,
        headerName: "nama kategori",
        minWidth: 100,
        type: "string",
      },
      {
        field: "actions",
        width: 80,
        headerName: "aksi",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip placement="bottom-start" title="Lihat Detail">
                <Info sx={{ color: theme.palette.info.main }} />
              </Tooltip>
            }
            label="detail"
            onClick={() => {
              navigate(`${params.row.id}`);
            }}
            sx={{ textTransform: "capitalize" }}
          />,
          <GridActionsCellItem
            icon={<Delete sx={{ color: theme.palette.error.main }} />}
            label="hapus"
            onClick={() => {
              handleClickOpenDialog();
              setSelectedProductCategory(params.row);
            }}
            showInMenu
            sx={{ textTransform: "capitalize" }}
          />,
        ],
      },
    ],

    // eslint-disable-next-line
    []
  );

  const fetchProductCategory = async () => {
    setProductCategory([]);
    setIsFetchProductCategory(true);

    try {
      const response = await axiosToken.get(
        `${process.env.REACT_APP_API_URL}/admin/product/category`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      response.data.forEach((props) => {
        setProductCategory((productCategory) => [
          ...productCategory,
          {
            id: props.product_category_id,
            ...props,
          },
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsFetchProductCategory(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }));
  };

  const handleClickOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleClickCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleClickDeleteProductCategory = async () => {
    setIsOpenDialog(false);
    try {
      const response = await axiosToken.delete(
        `${process.env.REACT_APP_API_URL}/admin/product/category`,
        {
          data: { productCategoryID: selectedProductCategory.id },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menghapus kategori",
          severity: "success",
        }));

        fetchProductCategory();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }
  };

  const onSubmitAddProductCategory = async (data) => {
    setIsLoadingButtonSubmit(true);

    try {
      const response = await axiosToken.post(
        `${process.env.REACT_APP_API_URL}/admin/product/category`,
        { productCategoryName: data.productCategoryName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "berhasil menambahkan kategori",
          severity: "success",
        }));

        reset();
        fetchProductCategory();
      } else {
        setSnackbar((snackbar) => ({
          ...snackbar,
          isOpen: true,
          message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
          severity: "error",
        }));
      }
    } catch (error) {
      setSnackbar((snackbar) => ({
        ...snackbar,
        isOpen: true,
        message: "terjadi kesalahan, silahkan coba beberapa saat lagi",
        severity: "error",
      }));
    }

    setIsLoadingButtonSubmit(false);
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);

  useEffect(() => {
    if (!isFetchProductCategory) {
      adminLayoutContext.setIsLoading(false);
    }
  }, [adminLayoutContext, isFetchProductCategory]);

  return (
    <Stack spacing={2}>
      {/* product category list */}
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">daftar kategori</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isFetchProductCategory ? (
              <DataGrid
                autoHeight
                columns={columns}
                initialState={{
                  pagination: {
                    pageCategory: 5,
                  },
                }}
                rows={productCategory}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
              />
            ) : (
              <Skeleton animation="wave" height={300} variant="rectangular" />
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* add product category */}
      <form onSubmit={handleSubmit(onSubmitAddProductCategory)}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">tambah kategori</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.productCategoryName && true}
                fullWidth
                helperText={
                  errors.productCategoryName &&
                  errors.productCategoryName.message
                }
                label="nama kategori"
                {...register("productCategoryName", {
                  required: "Field tidak boleh kosong",
                  maxLength: {
                    value: 30,
                    message: "Nama kategori maksimal 30 karakter",
                  },
                })}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  endIcon={<Send />}
                  loading={isLoadingButtonSubmit}
                  loadingPosition="end"
                  type="submit"
                  variant="contained"
                >
                  tambahkan kategori
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* dialog */}
        <Dialog
          open={isOpenDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClickCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ textTransform: "capitalize" }}>
            apakah anda yakin ingin menghapus kategori ini?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClickCloseDialog}>tidak</Button>
            <Button onClick={handleClickDeleteProductCategory}>ya</Button>
          </DialogActions>
        </Dialog>

        {/* snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar()}
          open={snackbar.isOpen}
        >
          <Alert
            onClose={() => handleCloseSnackbar()}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </form>
    </Stack>
  );
};

export default AdminProductCategory;
